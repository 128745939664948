function injectStyle (context) {
  require("!!style-loader!css-loader?{\"url\":false,\"minimize\":true}!../../../../../node_modules/vue-loader/lib/style-compiler/index?{\"optionsId\":\"0\",\"vue\":true,\"scoped\":false,\"sourceMap\":false}!postcss-loader?{}!sass-loader?{\"sassOptions\":{\"includePaths\":[\"/codebuild/output/src2351899271/src/html/themes/default/scss\",\"/codebuild/output/src2351899271/src/node_modules/compass-mixins/lib\",\"/codebuild/output/src2351899271/src/node_modules\"]}}!../../../../../node_modules/vue-loader/lib/selector?type=styles&index=0!./popover.vue")
}
/* script */
export * from "!!babel-loader?{\"babelrc\":false,\"presets\":[[\"@babel/preset-env\",{\"useBuiltIns\":\"usage\",\"corejs\":3}]],\"plugins\":[\"@babel/plugin-transform-runtime\",\"lodash\"]}!../../../../../node_modules/vue-loader/lib/selector?type=script&index=0!./popover.vue"
import __vue_script__ from "!!babel-loader?{\"babelrc\":false,\"presets\":[[\"@babel/preset-env\",{\"useBuiltIns\":\"usage\",\"corejs\":3}]],\"plugins\":[\"@babel/plugin-transform-runtime\",\"lodash\"]}!../../../../../node_modules/vue-loader/lib/selector?type=script&index=0!./popover.vue"
/* template */
import {render as __vue_render__, staticRenderFns as __vue_static_render_fns__} from "!!../../../../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-42699a90\",\"hasScoped\":false,\"optionsId\":\"0\",\"buble\":{\"transforms\":{}}}!../../../../../node_modules/vue-loader/lib/selector?type=template&index=0!./popover.vue"
/* template functional */
var __vue_template_functional__ = false
/* styles */
var __vue_styles__ = injectStyle
/* scopeId */
var __vue_scopeId__ = null
/* moduleIdentifier (server only) */
var __vue_module_identifier__ = null
import normalizeComponent from "!../../../../../node_modules/vue-loader/lib/runtime/component-normalizer"
var Component = normalizeComponent(
  __vue_script__,
  __vue_render__,
  __vue_static_render_fns__,
  __vue_template_functional__,
  __vue_styles__,
  __vue_scopeId__,
  __vue_module_identifier__
)

export default Component.exports
