<template>
  <div
    :class="topCss"
    :style="contentHeight"
  >
    <div
      v-show="pageProgress"
      id="page-progress"
      class="page-progress mdl-js-progress"
    />
    <div
      v-if="informationList.length > 0 && !isPrint"
      id="information-layout"
      ref="information"
      class="informationField"
    >
      <ul>
        <li
          v-for="(information, index) in informationList"
          :key="'information-' + information.id"
          class="mb5 mt5"
        >
          <div
            v-if="index === 0"
            class="floatRight"
          >
            <a
              href="javascript: void(0)"
              @click="onClickInformation()"
            ><span class="auiWFicon"><i class="fas fa-times" /></span></a>
          </div>
          <span v-html="information.content" />
        </li>
      </ul>
    </div>
    <div
      id="wrapper"
      class="base-layout mdl-js-layout"
    >
      <component
        :is="headerComponent"
        v-if="headerComponent"
      />
      <header v-if="!headerComponent">
        <div
          class="header mdl-layout__header-row"
        >
          <div
            v-show="active !== 'MyPage'"
            class="hdTitle"
          >
            <span
              v-cloak
              class="title"
            >{{ title }}</span>
          </div>
          <div
            v-show="active == 'MyPage'"
            class="hdPages"
          >
            <ul>
              <li
                v-for="tab in tabs"
                :key="tab.id"
                :class="{ current: currentTab == tab.id }"
              >
                <a
                  :href="
                    tab.title === 'マイページ'
                      ? contextPath + '/apps/portal'
                      : contextPath + '/apps/portal/pages/' + tab.id
                  "
                  class="name"
                >{{ tab.title }}</a>
                <aui-button
                  v-if="tab.hasAuthority"
                  :id="'menubar_button_tab_' + tab.id"
                  class="icon mini setting"
                  aria-label="ページ設定"
                >
                  <i class="fas fa-cog" />
                </aui-button>
                <ul
                  v-if="tab.hasAuthority"
                  :id="'menubar_tab_' + tab.id"
                  :for="'menubar_button_tab_' + tab.id"
                  class="aui-menu bottom-left"
                >
                  <li
                    class="item"
                    style="padding:0 16px"
                    @click="onClickCustomize(tab)"
                  >
                    設定
                  </li>
                  <li
                    v-show="tab.title !== 'マイページ'"
                    class="item"
                    style="padding:0 16px"
                    @click="onClickDelete(tab)"
                  >
                    削除
                  </li>
                </ul>
              </li>
            </ul>
            <div
              v-for="tab in tabs"
              :key="tab.id"
              :data-mdl-for="'menubar_button_tab_' + tab.id"
              class="aui-tooltip bottom"
            >
              ページ設定
            </div>
          </div>
          <div
            v-if="isLoaded"
            class="hdToolbar"
          >
            <ul v-if="name">
              <li v-show="trialMessage != ''">
                <div
                  class="alert"
                  style="padding:5px;"
                >
                  {{ trialMessage }}
                </div>
              </li>
              <li
                v-show="
                  productTrialMessage &&
                    productTrialMessage != ''
                "
              >
                <div
                  id="product-trial"
                  class="alert"
                  style="padding:5px;"
                >
                  <template v-if="isAdmin">
                    <a
                      href="javascript:void(0);"
                      @click="onClickAdminSettings"
                    >
                      {{ productTrialMessage }}</a>
                  </template>
                  <template v-else>
                    {{ productTrialMessage }}
                  </template>
                  <div
                    v-if="!isTrialEnd"
                    class="aui-tooltip bottom"
                    style="white-space: pre-wrap;"
                    data-mdl-for="product-trial"
                    v-text="toolTipTrialMessage"
                  />
                </div>
              </li>
              <li
                v-show="hasSearchForm"
                v-html="searchForm"
              />

              <li>
                <div class="menu-support">
                  <aui-button
                    id="support-menu"
                    class="icon"
                    aria-label="サポートメニュー"
                  >
                    <div
                      :data-badge="channelIOUnreadCount"
                      class="mr0 badge mdl-badge mdl-badge--overlap"
                    >
                      <i class="far fa-question-circle" />
                    </div>
                  </aui-button>
                  <aui-tooltip class="bottom">
                    サポートメニュー
                  </aui-tooltip>
                  <aui-menu
                    class="bottom-right"
                    for="support-menu"
                  >
                    <li
                      class="item"
                      @click="onClickReleaseNote"
                    >
                      アップデート情報<small><i class="fas fa-external-link-alt fcGray ml5" /></small>
                    </li>
                    <li
                      class="item"
                      @click="onClickHelp"
                    >
                      ヘルプ<small><i class="fas fa-external-link-alt fcGray ml5" /></small>
                    </li>
                    <li
                      v-show="isMessageScreen"
                      class="item"
                      @click="onClickStartGuide"
                    >
                      スタートガイド
                    </li>
                    <li
                      class="item"
                      @click="onClickCase"
                    >
                      導入事例<small><i class="fas fa-external-link-alt fcGray ml5" /></small>
                    </li>
                    <li
                      v-show="viewSupport"
                      class="item"
                      @click="onClickChatSupportLauncher"
                    >
                      <span
                        :data-badge="channelIOUnreadCountMini"
                        class="badge mini mdl-badge mdl-badge--overlap"
                        style="margin-right:5px"
                      >
                        チャットサポート</span>
                    </li>
                  </aui-menu>
                </div>
              </li>

              <li
                v-show="hasSettings"
              >
                <aui-button
                  id="menu-settings"
                  tabindex="-1"
                  class="icon"
                  aria-label="設定メニュー"
                >
                  <i class="fas fa-cog" />
                </aui-button>
                <aui-tooltip class="bottom">
                  設定メニュー
                </aui-tooltip>
                <aui-menu
                  class="bottom-right"
                  for="menu-settings"
                >
                  <li
                    v-for="item in settingsMenu"
                    v-show="
                      (item.viewKey &&
                        hasViewKey[item.viewKey] &&
                        hasViewKey[item.viewKey] == 'T') ||
                        (!item.viewKey && (!item.isAdmin || isAdmin))
                    "
                    :key="item.label"
                    class="item"
                    @click="onClickSettings(item)"
                  >
                    {{ item.label }}
                  </li>
                </aui-menu>
              </li>
              <li>
                <div class="menu-activity">
                  <aui-button
                    id="header-menu-activity"
                    class="icon popover-link"
                    aria-label="お知らせ"
                    @click.native="onClickHeaderActivity($event)"
                  >
                    <div
                      :data-badge="badge.activity"
                      class="mr0 badge mdl-badge mdl-badge--overlap popover-link"
                    >
                      <i class="far fa-bell popover-link" />
                    </div>
                  </aui-button>
                  <aui-tooltip class="bottom">
                    お知らせ
                  </aui-tooltip>
                </div>
              </li>
              <li>
                <div
                  class="menu-system"
                >
                  <aui-button
                    id="header-menu-system"
                    class="icon-with-arrow"
                    aria-label="メインメニュー"
                  >
                    <div
                      :data-badge="badge.setup"
                      class="mr0 badge mdl-badge mdl-badge--overlap"
                    >
                      <img
                        :src="profileImageUrl"
                        :alt="name"
                        width="32"
                        height="32"
                        class="avatar_m"
                      >
                    </div>
                  </aui-button>
                  <aui-tooltip class="bottom">
                    メインメニュー
                  </aui-tooltip>
                  <aui-menu
                    class="bottom-right"
                    for="header-menu-system"
                  >
                    <li class="header-menu__user">
                      <div class="aui-user-name">
                        <span class="aui-user-icon">
                          <span
                            class="aui-user-icon__shape"
                          >
                            <span class="aui-user-icon__border">
                              <img
                                :src="profileImageUrl"
                                :alt="name"
                                class="avatar_l"
                              >
                            </span>
                          </span>
                        </span>
                        <!-- todo:在席ステータス表示箇所 -->
                        <div style="display: flex; flex-flow: column;">
                          <span
                            v-cloak
                            class="aui-user-name__text"
                          >{{ name }}</span>
                        </div>
                      </div>
                    </li>
                    <li
                      class="hr"
                    >
                      <hr>
                    </li>
                    <li
                      v-if="isPresentStatusValid && isNotificationActive"
                      class="item"
                      @click="onClickChangePresence"
                    >
                      <span
                        class="badge mini mdl-badge mdl-badge--overlap"
                        style="margin-right:5px; padding-right:5px;"
                      >ステータス変更</span>
                    </li>
                    <li
                      class="item"
                      @click="onClickUserSettings"
                    >
                      <span
                        :data-badge="badge.setupAccount"
                        class="badge mini mdl-badge mdl-badge--overlap"
                        style="margin-right:5px; padding-right:5px;"
                      >個人設定</span>
                    </li>
                    <li
                      v-if="isNotificationActive"
                      class="item"
                      onclick="aipo.notification.openDialog();"
                    >
                      <span
                        :data-badge="badge.setupNotification"
                        class="badge mini mdl-badge mdl-badge--overlap"
                        style="margin-right:5px; padding-right:5px;"
                      >通知設定</span>
                    </li>
                    <li
                      v-show="isAdmin"
                      class="item"
                      @click="onClickAdminSettings"
                    >
                      管理設定
                    </li>
                    <li
                      v-show="isAdmin"
                      class="item"
                      @click="onClickAddUser"
                    >
                      <span
                        :data-badge="badge.setupInvite"
                        class="badge mini mdl-badge mdl-badge--overlap"
                        style="margin-right:5px; padding-right:5px;"
                      >ユーザー招待</span>
                    </li>
                    <li
                      class="hr"
                    >
                      <hr>
                    </li>
                    <li
                      class="item"
                      @click="onClickLogout"
                    >
                      ログアウト
                    </li>
                  </aui-menu>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div class="launcher mdl-layout__drawer">
        <div
          v-if="isLoaded"
          class="logo"
        >
          <div
            id="menu-reload"
            @click="onClickLogo"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="scheduling-page__logo"
              width="128"
              height="128"
              viewBox="0 0 93 113.1"
            >
              <g data-name="symbol">
                <path d="M62.6,112.4c3.1-0.5,6.1-1.3,9-2.4c5.1-1.9,9.9-4.7,14.2-8.3c-2.8-29-33.2-43.4-33.2-43.5c-0.3-0.2-0.6-0.5-0.7-0.8 c-0.3-0.8,0.1-1.6,0.9-1.8c17-6,16.5-14.5,15.7-17.6L7.3,59.5C8,62.1,10,68.1,14.1,68c0.9-0.1,1.9-0.2,2.8-0.3c1-0.1,2-0.1,2.9-0.1 c11.8,0,22.6,4.8,30.3,12.6c7.8,7.8,12.6,18.5,12.6,30.3C62.7,111.1,62.6,111.7,62.6,112.4L62.6,112.4z" /> <path d="M29.4,105.9c3.4,2.1,7,3.7,10.7,4.9c4.7,1.5,9.6,2.3,14.7,2.3c1.7,0,3.3-0.1,5-0.3c0-0.2,0-0.3,0-0.5c0-0.5,0-1.1,0-1.8 c0-11-4.5-21-11.7-28.3c-3.2-3.2-7-5.9-11.1-7.9c0.3,0.4,0.6,0.8,0.9,1.2c2.6,3.4,4,7.5,4,12c0,4.7-1.6,9-4.4,12.4 C35.4,102.5,32.6,104.6,29.4,105.9" /> <path
                  class="st1"
                  d="M40.4,39l5.1-6.1c1.2-1.4,2.3-3,3.1-4.7c1.7-3.4,2.6-7.1,2.6-10.9c0-2.8-0.5-5.5-1.4-8.1C49,7,47.9,5,46.6,3.1 l-0.3-0.5l2.6-1.4l0,0l1.4-0.7l0.3,0.4c1.6,2.3,2.8,4.7,3.7,7.4c1,2.9,1.5,6,1.5,9.1c0,6.2-2,12.2-5.7,17.2c-3.7,5-9,8.6-14.9,10.4 c-1.3,0.4-2.7,0.7-4,0.9c-1.4,0.2-2.7,0.3-4.1,0.3l-0.5,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.5,0-0.7,0c-0.2,0-0.5,0-0.7,0 c-0.8-0.1-1.6-0.2-2.4-0.3c-0.8-0.1-1.6-0.3-2.4-0.5C15.1,44,11,41.8,7.5,38.6c-3.2-2.9-5.7-6.5-7.3-10.5L0,27.7l4-2.2l0.2,0.5 c1.4,3.6,3.7,6.9,6.6,9.4c2.9,2.6,6.4,4.4,10.1,5.4l1.9,0.5l0.6-1.8c0.3-1,0.6-2,0.8-3c0.3-1.5,0.4-3,0.4-4.5 c0-2.8-0.5-5.6-1.4-8.2c-0.8-2.2-1.9-4.3-3.3-6.2l-0.4-0.5l2.6-1.4l0,0l1.4-0.7l0.3,0.4c1.6,2.3,2.9,4.8,3.8,7.5 c1,3,1.5,6.1,1.5,9.2c0,1.5-0.1,3-0.3,4.4c-0.1,0.8-0.3,1.6-0.5,2.3l-0.7,3l3.1-0.5c0.3-0.1,0.7-0.1,1-0.2l0.8-0.2l0.5-0.6 c1.6-2,2.8-4.3,3.7-6.7c0.9-2.6,1.4-5.3,1.4-8.1c0-2.9-0.5-5.8-1.5-8.5c-0.9-2.3-2.1-4.5-3.6-6.4l-0.4-0.5L35,8.7l0,0l1.4-0.8 l0.3,0.4c1.8,2.3,3.1,4.9,4.1,7.7c1.1,3.1,1.6,6.3,1.6,9.5c0,1.8-0.2,3.6-0.5,5.4L40.4,39z"
                />
              </g>
            </svg>
          </div>
        </div>
        <div
          id="nav"
          class="nav"
        >
          <nav>
            <a
              v-show="activeApps.includes('MyPage')"
              v-cloak
              id="menu-mypage"
              :href="contextPath + '/apps/portal'"
              :class="{ active: active == 'MyPage' }"
              :style="'order: ' + activeApps.indexOf('MyPage')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="ポータル"
            ><div class="badge mdl-badge mdl-badge--overlap">
               <i class="fas fa-home fa-fw" />
             </div>
              <label>ポータル</label></a>
            <a
              v-show="activeApps.includes('Schedule')"
              v-cloak
              id="menu-calendar"
              :href="contextPath + '/apps/calendar'"
              :class="{ active: active == 'Schedule' }"
              :style="'order: ' + activeApps.indexOf('Schedule')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="カレンダー"
            ><div class="badge mdl-badge mdl-badge--overlap">
               <i class="far fa-calendar-alt fa-fw" />
             </div>
              <label>カレンダー</label></a>
            <a
              v-show="activeApps.includes('Schedule') && schedulingEnabled"
              v-cloak
              id="menu-scheduling-vote"
              :href="contextPath + '/apps/calendar/scheduling/votes'"
              :class="{ active: active == 'SchedulingVote' }"
              :style="'order: ' + activeApps.indexOf('Scheduling')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="出欠確認"
            ><div
               :data-badge="badge.scheduling"
               class="badge mdl-badge mdl-badge--overlap"
             >
               <i class="far fa-calendar-check fa-fw" />
             </div>
              <label>出欠確認</label></a>
            <a
              v-show="activeApps.includes('Schedule') && schedulingEnabled"
              v-cloak
              id="menu-scheduling"
              :href="contextPath + '/apps/calendar/scheduling'"
              :class="{ active: active == 'Scheduling' }"
              :style="'order: ' + activeApps.indexOf('Scheduling')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="日程調整"
            ><div
               class="badge mdl-badge mdl-badge--overlap"
             >
               <i class="fas fa-user-clock" />
             </div>
              <label>日程調整</label></a>
            <a
              v-show="activeApps.includes('Message')"
              v-cloak
              id="menu-chat"
              :href="contextPath + '/apps/chat'"
              :class="{ active: active == 'Message' }"
              :style="'order: ' + activeApps.indexOf('Message')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="チャット"
            ><div
               :data-badge="badge.chat"
               class="badge mdl-badge mdl-badge--overlap"
             >
               <i class="far fa-comment fa-fw" />
             </div>
              <label>チャット</label></a>
            <a
              v-show="activeApps.includes('Timeline')"
              v-cloak
              id="menu-timeline"
              :href="contextPath + '/apps/timeline'"
              :class="{ active: active == 'Timeline' }"
              :style="'order: ' + activeApps.indexOf('Timeline')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="タイムライン"
            ><div
               :data-badge="badge.timeline"
               class="badge mini  mdl-badge mdl-badge--overlap"
             >
               <i class="fas fa-history fa-fw" />
             </div>
              <label>タイムライン</label></a>
            <a
              v-show="activeApps.includes('Msgboard')"
              v-cloak
              id="menu-bbs"
              :href="contextPath + '/apps/bbs'"
              :class="{ active: active == 'Msgboard' }"
              :style="'order: ' + activeApps.indexOf('Msgboard')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="掲示板"
            ><div
               :data-badge="badge.msgboard"
               class="badge mini mdl-badge mdl-badge--overlap"
             >
               <i class="far fa-clipboard fa-fw" />
             </div>
              <label>掲示板</label></a>
            <a
              v-show="activeApps.includes('ToDo')"
              v-cloak
              id="menu-todo"
              :href="contextPath + '/apps/todo'"
              :class="{ active: active == 'ToDo' }"
              :style="'order: ' + activeApps.indexOf('ToDo')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="ToDo"
            ><div class="badge  mdl-badge mdl-badge--overlap">
               <i class="far fa-check-square fa-fw" />
             </div>
              <label>ToDo</label></a>
            <a
              v-show="activeApps.includes('ExtTimecard')"
              v-cloak
              id="menu-timecard"
              :href="contextPath + '/apps/timecard'"
              :class="{ active: active == 'ExtTimecard' }"
              :style="'order: ' + activeApps.indexOf('ExtTimecard')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="タイムカード"
            ><div class="badge  mdl-badge mdl-badge--overlap">
               <i class="far fa-clock fa-fw" />
             </div>
              <label>タイムカード</label></a>
            <a
              v-show="activeApps.includes('Workflow')"
              v-cloak
              id="menu-workflow"
              :href="contextPath + '/apps/workflow'"
              :class="{ active: active == 'Workflow' }"
              :style="'order: ' + activeApps.indexOf('Workflow')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="ワークフロー"
            ><div
               :data-badge="badge.workflow"
               class="badge mdl-badge mdl-badge--overlap"
             >
               <i class="fas fa-sitemap fa-fw" />
             </div>
              <label>ワークフロー</label></a>
            <a
              v-show="activeApps.includes('Cabinet')"
              v-cloak
              id="menu-folder"
              :href="contextPath + '/apps/folder'"
              :class="{ active: active == 'Cabinet' }"
              :style="'order: ' + activeApps.indexOf('Cabinet')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="フォルダ"
            ><div
               :data-badge="badge.cabinet"
               class="badge mini mdl-badge mdl-badge--overlap"
             >
               <i class="fas fa-folder-open fa-fw" />
             </div>
              <label>フォルダ</label></a>
            <a
              v-show="activeApps.includes('Note')"
              v-cloak
              id="menu-note"
              :href="contextPath + '/apps/phonememo'"
              :class="{ active: active == 'Note' }"
              :style="'order: ' + activeApps.indexOf('Note')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="伝言メモ"
            ><div
               :data-badge="badge.note"
               class="badge mini mdl-badge mdl-badge--overlap"
             >
               <i class="fas fa-phone-square fa-fw" />
             </div>
              <label>伝言メモ</label></a>
            <a
              v-show="activeApps.includes('WebMail')"
              v-cloak
              id="menu-webmail"
              :href="contextPath + '/apps/webmail'"
              :class="{ active: active == 'WebMail' }"
              :style="'order: ' + activeApps.indexOf('WebMail')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="Webメール"
            ><div class="badge  mdl-badge mdl-badge--overlap">
               <i class="far fa-envelope fa-fw" />
             </div>
              <label>Webメール</label></a>
            <a
              v-show="activeApps.includes('AddressBook')"
              v-cloak
              id="menu-addressbook"
              :href="contextPath + '/apps/addressbook'"
              :class="{ active: active == 'AddressBook' }"
              :style="'order: ' + activeApps.indexOf('AddressBook')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="アドレス帳"
            ><div class="badge  mdl-badge mdl-badge--overlap">
               <i class="far fa-address-card fa-fw" />
             </div>
              <label>アドレス帳</label></a>
            <a
              v-show="activeApps.includes('Blog')"
              v-cloak
              id="menu-blog"
              :href="contextPath + '/apps/blog'"
              :class="{ active: active == 'Blog' }"
              :style="'order: ' + activeApps.indexOf('Blog')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="ブログ"
            ><div class="badge  mdl-badge mdl-badge--overlap">
               <i class="fas fa-book fa-fw" />
             </div>
              <label>ブログ</label></a>
            <a
              v-show="activeApps.includes('Report')"
              v-cloak
              id="menu-report"
              :href="contextPath + '/apps/report'"
              :class="{ active: active == 'Report' }"
              :style="'order: ' + activeApps.indexOf('Report')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="報告書"
            ><div
               :data-badge="badge.report"
               class="badge mini mdl-badge mdl-badge--overlap"
             >
               <i class="far fa-copy fa-fw" />
             </div>
              <label>報告書</label></a>
            <a
              v-show="activeApps.includes('Project')"
              v-cloak
              id="menu-project"
              :href="contextPath + '/apps/project'"
              :class="{ active: active == 'Project' }"
              :style="'order: ' + activeApps.indexOf('Project')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="プロジェクト"
            ><div class="badge  mdl-badge mdl-badge--overlap">
               <i class="fas fa-tasks fa-fw" />
             </div>
              <label>プロジェクト</label></a>
            <a
              v-show="activeApps.includes('Gpdb')"
              v-cloak
              id="menu-webdb"
              :href="contextPath + '/apps/webdb'"
              :class="{ active: active == 'Gpdb' }"
              :style="'order: ' + activeApps.indexOf('Gpdb')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="Webデータベース"
            ><div class="badge  mdl-badge mdl-badge--overlap">
               <i class="fas fa-database fa-fw" />
             </div>
              <label>Webデータベース</label></a>
            <a
              v-show="activeApps.includes('Wiki')"
              v-cloak
              id="menu-wiki"
              :href="contextPath + '/apps/wiki'"
              :class="{ active: active == 'Wiki' }"
              :style="'order: ' + activeApps.indexOf('Wiki')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="Wiki"
            ><div
               :data-badge="badge.wiki"
               class="badge mini mdl-badge mdl-badge--overlap"
             >
               <i class="fas fa-pen-square fa-fw" />
             </div>
              <label>Wiki</label></a>
            <a
              v-show="activeApps.includes('Equipment')"
              v-cloak
              id="menu-equipment"
              :href="contextPath + '/apps/equipment'"
              :class="{ active: active == 'Equipment' }"
              :style="'order: ' + activeApps.indexOf('Equipment')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="備品"
            ><div
               :data-badge="badge.equipment"
               class="badge mini mdl-badge mdl-badge--overlap"
             >
               <i class="fas fa-archive fa-fw" />
             </div>
              <label>備品</label></a>
            <a
              v-show="activeApps.includes('Safety')"
              v-cloak
              id="menu-safety"
              :href="contextPath + '/apps/safety'"
              :class="{ active: active == 'Safety' }"
              :style="'order: ' + activeApps.indexOf('Safety')"
              class="mdl-navigation__link mdl-js-button"
              aria-label="安否確認"
            ><div
               :data-badge="badge.safety"
               class="badge mini mdl-badge mdl-badge--overlap"
             >
               <i class="fas fa-medkit fa-fw" />
             </div>
              <label>安否確認</label></a>
            <a
              v-show="viewUserList"
              v-cloak
              id="menu-userlist"
              :href="contextPath + '/apps/userlist'"
              :class="{ active: active == 'UserList' }"
              style="order: 99"
              class="mdl-navigation__link mdl-js-button"
              aria-label="ユーザー名簿"
            ><div
               class="badge mini mdl-badge mdl-badge--overlap"
             >
               <i class="fas fa-users fa-fw" />
             </div>
              <label>ユーザー名簿</label></a>
            <a
              v-cloak
              id="menu-trial"
              :href="trialPagePath"
              style="order: 99"
              class="mdl-navigation__link mdl-js-button"
              aria-label="無料トライアル"
            ><div class="badge  mdl-badge mdl-badge--overlap">
               <i class="fas fa-plus-circle fa-fw" />
             </div>
              <label>無料トライアル</label></a>
          </nav>
        </div>
        <div class="mdl-layout-spacer" />
      </div>
      <div class="drawer mdl-layout__drawer">
        <div class="mdl-layout-spacer" />
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-reload"
      >
        再読み込み
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-mypage"
      >
        ポータル
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-calendar"
      >
        カレンダー
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-scheduling"
      >
        日程調整
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-scheduling-vote"
      >
        出欠確認
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-chat"
      >
        チャット
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-timeline"
      >
        タイムライン
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-bbs"
      >
        掲示板
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-todo"
      >
        ToDo
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-timecard"
      >
        タイムカード
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-workflow"
      >
        ワークフロー
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-folder"
      >
        フォルダ
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-note"
      >
        伝言メモ
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-webmail"
      >
        Webメール
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-addressbook"
      >
        アドレス帳
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-blog"
      >
        ブログ
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-report"
      >
        報告書
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-project"
      >
        プロジェクト
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-webdb"
      >
        Webデータベース
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-wiki"
      >
        Wiki
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-equipment"
      >
        備品
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-safety"
      >
        安否確認
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-userlist"
      >
        ユーザー名簿
      </div>
      <div
        v-if="isLoaded"
        class="aui-tooltip right"
        data-mdl-for="menu-trial"
      >
        アプリの無料トライアル
      </div>
      <keep-alive>
        <component
          :is="currentContent"
          ref="sub"
          :params="currentParams"
        />
      </keep-alive>
      <div
        v-show="fab != ''"
        class="fab-container"
        style="display:none"
      >
        <aui-button
          :onclick="fab"
          class="fab"
        >
          <i class="fas fa-pencil-alt" />
        </aui-button>
      </div>
      <div class="mdl-layout__obfuscator-right" />
    </div>
    <div
      aria-live="assertive"
      aria-atomic="true"
      aria-relevant="text"
      class="mdl-snackbar mdl-js-snackbar"
    >
      <div class="mdl-snackbar__text" />
      <button
        type="button"
        class="mdl-snackbar__action"
      />
    </div>
    <iframe
      id="sharedStateIframe"
      name="sharedStateIframe"
      src="about:blank"
      frameborder="no"
      scrolling="no"
      width="0"
      height="0"
    />
    <iframe
      id="socketIframe"
      name="socketIframe"
      src="about:blank"
      frameborder="no"
      scrolling="no"
      width="0"
      height="0"
    />
    <aui-modal
      :component="modal.component"
      :params="modal.params"
      :width="modal.width"
      @close="onCloseModal"
    />
    <div
      id="dummyText"
      style="display:none"
    />
    <activity-popover

      ref="activity-popup"
      :mouse-event="activityPopup.mouseEvent"
      :outer-rect="activityPopup.outerRect"
    />
    <component
      :is="lazyContent"
      v-if="lazyContent"
    />
  </div>
</template>

<script>
import toolkit from '../../../toolkit';
import ActivityPopover from '../../common/components/activity-popover.vue';

export default {
  components: {
    ActivityPopover,
  },
  data() {
    return {
      drawer: 'system',
      informationHeight: 0,
    };
  },
  computed: {
    pageProgress() {
      return this.$store.state.workspace.pageProgress;
    },
    title() {
      return this.$store.state.workspace.title;
    },
    contextPath() {
      return this.$store.state.workspace.contextPath;
    },
    tabLink(tab) {
      return tab.title === 'マイページ'
        ? `${this.$store.state.workspace.contextPath}/`
        : `${this.$store.state.workspace.contextPath}/portal/pages/${tab.id}`;
    },
    trialMessage() {
      return this.$store.state.workspace.trialMessage;
    },
    settingsUrl() {
      return `${this.$store.state.workspace.contextPath}/portal/settings/`;
    },
    adminSettingsUrl() {
      return `${this.$store.state.workspace.contextPath}/portal/admin/`;
    },
    guideUrl() {
      return `${this.$store.state.workspace.contextPath}/apps/guide`;
    },
    userListUrl() {
      return `${this.$store.state.workspace.contextPath}/apps/userlist`;
    },
    tabs() {
      return this.$store.state.workspace.tabs;
    },
    topCss() {
      return this.$store.state.workspace.isPrintView ? 'print' : this.$store.state.workspace.topCss;
    },
    name() {
      return this.$store.state.user.name;
    },
    company() {
      return this.$store.state.user.company;
    },
    profileImageUrl() {
      return this.$store.state.user.profileImageUrl;
    },
    isAdmin() {
      return this.$store.state.user.isAdmin;
    },
    viewUserList() {
      return this.$store.state.user.authority.viewUserList;
    },
    viewSupport() {
      return this.$store.state.user.authority.viewSupport;
    },
    isNotificationActive() {
      return this.$store.state.user.authority.isNotificationActive;
    },
    activeApps() {
      return this.$store.state.workspace.activeApps;
    },
    active() {
      return this.$store.state.workspace.active;
    },
    currentTab() {
      return this.$store.state.workspace.currentTab;
    },
    currentContent() {
      return this.$store.state.workspace.currentContent;
    },
    currentParams() {
      return this.$store.state.workspace.currentParams;
    },
    lazyContent() {
      return this.$store.state.workspace.lazyContent;
    },
    badge() {
      return this.$store.state.workspace.badge;
    },
    fixed() {
      return this.$store.state.workspace.fixed;
    },
    fab() {
      return this.$store.state.workspace.fab;
    },
    headerSubmenu() {
      return this.$store.state.workspace.headerSubmenu;
    },
    searchForm() {
      return this.$store.state.workspace.searchForm;
    },
    hasSearchForm() {
      return this.$store.state.workspace.options.hasSearchForm;
    },
    hasSettings() {
      return this.$store.state.workspace.options.settingsMenu.length > 0;
    },
    settingsMenu() {
      return this.$store.state.workspace.options.settingsMenu;
    },
    productTrialMessage() {
      const daysLeft = this.$store.state.workspace.productTrialMessage[this.active];
      if (daysLeft !== undefined) {
        return daysLeft < 0 ? '試用終了' : `残り${daysLeft}日`;
      }
      return '';
    },
    isTrialEnd() {
      const daysLeft = this.$store.state.workspace.productTrialMessage[this.active];
      return daysLeft !== undefined && daysLeft < 0;
    },
    toolTipTrialMessage() {
      const daysLeft = this.$store.state.workspace.productTrialMessage[this.active];
      if (daysLeft !== undefined && daysLeft >= 0) {
        let message = `無料お試し：残り${daysLeft}日`;
        if (this.isAdmin) {
          message
        += '\n画面右上のメインメニューを押して「管理設定」の「基本情報」にある「契約を申し込む」からお申し込みいただけます';
        }
        return message;
      }
      return '';
    },
    headerComponent() {
      return this.$store.state.workspace.headerComponent;
    },
    hasViewKey() {
      return this.$store.state.workspace.trial;
    },
    modal() {
      return this.$store.state.workspace.modal;
    },
    isNewCalendarVersion() {
      return this.$store.state.workspace.versions.calendar === 2;
    },
    isSwitchVersion() {
      return this.$store.state.workspace.trial.calendar === 'T';
    },
    activityPopup() {
      return this.$store.state.workspace.activityPopup;
    },
    trialPagePath() {
      let portletId = '';
      if (!this.isAdmin) {
        const { portlets } = this.$store.state.workspace;
        portletId = portlets.Guide;
      }
      return `${this.contextPath}/apps/trial/${portletId}`;
    },
    informationList() {
      return this.$store.state.workspace.information;
    },
    isPrint() {
      return this.$store.state.workspace.isPrintView;
    },
    contentHeight() {
      return {
        '--information-height': `${this.informationHeight}px`,
        height: '100%',
        width: '100%',
      };
    },
    schedulingEnabled() {
      const { activeApps } = this;
      return activeApps ? activeApps.includes('Schedule') && activeApps.includes('AddOnScheduling') : false;
    },
    channelIOUnreadCount() {
      return this.$store.state.workspace.channelIOUnreadCount;
    },
    channelIOUnreadCountMini() {
      return this.$store.state.workspace.channelIOUnreadCount > 0 ? ' ' : null;
    },
    user() {
      return this.$store.state.user;
    },
    isLoaded() {
      return this.$store.state.workspace.domLoaded;
    },
    isIE() {
      return window.platform.name === 'IE';
    },
    isDesktop() {
      return window.platform.name === 'Electron' && window.platform.ua.indexOf('Aipo') !== -1;
    },
    isPresentStatusValid() {
      return this.$store.state.user.authority.isPresentStatusValid;
    },
    isMessageScreen() {
      return this.$store.state.workspace.active === 'Message';
    },
  },
  mounted() {
  },
  updated() {
    // データが変更後、仮想 DOM が再描画そしてパッチを適用
    this.$nextTick(() => {
      // ビュー全体が再レンダリングされた後にのみ実行されるコード
      let infoHeight = 0;
      const element = this.$refs.information;
      if (element) {
        const { height } = element.getBoundingClientRect();
        infoHeight = height;
      } else {
        // お知らせ掲載終了時
        infoHeight = 0;
      }
      const { ieinformation, desktopinformation } = this.$refs;
      if (ieinformation) {
        const { height } = ieinformation.getBoundingClientRect();
        infoHeight += height;
      }
      if (desktopinformation) {
        const { height } = desktopinformation.getBoundingClientRect();
        infoHeight += height;
      }
      this.informationHeight = infoHeight;
    });
  },
  methods: {
    onClickLogo() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then((registration) => {
          registration.update();
        });
      }
      window.location.href = `${this.$store.state.workspace.contextPath}/`;
    },
    onClickCustomize(tab) {
      aipo.common.showDialog(`?template=CustomizeFormScreen&js_pane=${tab.id}`);
    },
    onClickSettings(item) {
      const {
        active,
        portlets,
      } = this.$store.state.workspace;
      const { link, type, func } = item;
      if (type === 'dialog') {
        let portletId = null;
        if (active === 'MyPage') {
          portletId = type.isAdmin
            ? portlets.SaaSSysInfo
            : portlets.AccountPerson;
        } else if (active && portlets) {
          portletId = portlets[active];
        }
        if (portletId) {
          aipo.common.showDialog(
            toolkit.updateQueryStringParameter(link, 'js_peid', portletId),
          );
        }
      } else if (type === 'page') {
        App.navigateTo(link);
      } else if (type === 'custom') {
        func();
      }
    },
    onClickDelete(tab) {
      aipo.customize.deleteTabSubmit(
        `?secid=${
          this.$store.state.user.secid
        }&template=PageFormJSONScreen&entityid=${tab.id}&mode=delete&js_pane=${
          tab.id
        }`,
        tab.id,
        () => {
          App.navigateTo('/', false);
          App.updateStateTabs();
        },
      );
    },
    onClickAvatar() {
      this.drawer = 'system';
      if (window.ChannelIO) {
        // チャットサポート非表示
        window.ChannelIO('hideMessenger');
      }
      App.openDrawer();
      aipo.IfrGadgetService.prototype.requestSupportCapacity();
    },
    onClickChatSupport() {
      App.closeDrawer();
      if (window.ChannelIO) {
        // チャットサポートを表示
        window.ChannelIO('showMessenger');
      }
    },
    onClickChatSupportLauncher() {
      if (window.ChannelIO) {
        // チャットサポートを表示
        window.ChannelIO('showMessenger');
      } else {
        window.open('https://kurojica.tayori.com/q/schedule-help/');
      }
    },
    onClickLogout() {
      this.$store.commit('workspace/pageProgress', true);
      window.location.href = '?action=SaaSJLogoutUser';
    },
    onCloseModal() {
      const { previousPath } = this.$store.state.workspace;
      if (previousPath) {
        window.history.pushState(null, '', previousPath);
        this.$store.commit('workspace/set', { previousPath: '' });
      }
      this.$store.commit('workspace/modal', { component: null, width: '700px' });
    },
    onClickHelp() {
      window.open('https://kurojica.tayori.com/q/schedule-help/');
    },
    onClickReleaseNote() {
      window.open('https://kurojica.com/schedule/category/update/');
    },
    onClickCase() {
      window.open('https://kurojica.com/schedule/case/');
    },
    onClickAdminSettings() {
      window.location.href = this.adminSettingsUrl;
    },
    onClickAddUser() {
      const id = this.$store.state.workspace.portlets.SaaSSysInfo;
      aipo.common.showDialog(`?js_peid=${id}&template=StartGuideFormScreen&entityid=new`, `${id}`, aipo.account_user.onLoadUserDialog);
    },
    onClickUserSettings() {
      window.location.href = this.settingsUrl;
    },
    onClickHeaderActivity(mouseEvent) {
      if (window.ChannelIO) {
        // チャットサポート非表示
        window.ChannelIO('hideMessenger');
      }
      this.$store.commit('workspace/activityPopup', {
        mouseEvent,
        outerRect: this.$el.getBoundingClientRect(),
      });
    },
    async onClickInformation() {
      const params = {};
      params.secid = this.$store.state.user.secid;
      params.isRead = '*';
      const { data, error } = await this.$api.post('?template=CheckInformationJSONScreen', params);
      if (!error) {
        if (!data.errors) {
          this.$store.commit('workspace/information');
          this.informationHeight = 0;
        }
      } else {
        // console.log(error);
      }
    },
    onClickChangePresence() {
      aipo.common.showDialog('?template=PresenceFormScreen');
    },
    onClickStartGuide() {
      if (window.ChannelIO) {
        window.ChannelIO('track', 'open startguide', { app: 'message' });
      }
      App.navigateTo('/apps/guide/message');
    },
  },
};
</script>


<style lang="scss">

.apps {
  .base-layout {
    main {
      height: calc(100vh - 60px - var(--information-height));

    }
  }
  .mdl-layout__container {
    height: calc(100vh  - var(--information-height));
  }
}

.full .auiPortlet {
  min-height: calc(100vh - 60px - var(--information-height));
}

.ly-content-center {
  height: calc(100vh - 60px - var(--information-height));
}

.aui-menu,
.mdl-menu__container,
.mdl-menu__outline {
  max-height: calc(100vh - 60px  - var(--information-height));
}

</style>
