import Vue from 'vue';
import SimpleBar from 'simplebar';
import HomeComponent from './home/containers/home.vue';
import App from './app';
import toolkit from '../toolkit';

toolkit.setViewportProperty(document.documentElement);
window.addEventListener('resize', toolkit.setViewportProperty(document.documentElement), true);

SimpleBar.removeObserver();
window.SimpleBar = SimpleBar;
window.toolkit = toolkit;
const DesktopApp = App.extend({
  render: h => h(HomeComponent),
});
window.App = DesktopApp;
window.Vue = Vue;

Vue.component('app', DesktopApp);
