import { select } from 'd3-selection';
import { timeout } from 'd3-timer';
import forEach from 'lodash/forEach';
import throttle from 'lodash/throttle';
import Vue from 'vue';
import { mapActions } from 'vuex';
import toolkit from '../toolkit';
import AuiButton from './aui/aui-button.vue';
import AuiMenu from './aui/aui-menu.vue';
import AuiModal from './aui/aui-modal.vue';
import AuiPageProgress from './aui/aui-page-progress.vue';
import AuiSpinner from './aui/aui-spinner.vue';
import AuiTooltip from './aui/aui-tooltip.vue';
import Popover from './common/components/popover.vue';
import './mdl';
import store from './store';

Vue.config.performance = process.env.NODE_ENV !== 'production';

window.platform = require('platform');

Vue.component('aui-button', AuiButton);
Vue.component('aui-menu', AuiMenu);
Vue.component('aui-tooltip', AuiTooltip);
Vue.component('aui-modal', AuiModal);
Vue.component('aui-spinner', AuiSpinner);
Vue.component('aui-page-progress', AuiPageProgress);
Vue.component('popover', Popover);

const App = Vue.extend({
  props: {
    workspaceState: {
      type: Object,
      required: true,
    },
    contextPath: {
      type: String,
      required: true,
    },
    initialRoute: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  store,
  data() {
    return {
      isRequireUpdateCal: false,
      reloadCalendar:
        throttle(() => {
          if (this.$store.state.calendar && !this.$store.state.workspace.isMobile) {
            if (this.$store.state.workspace.active === 'Schedule') {
              this.$store.dispatch('calendar/reloadEvents');
              this.isRequireUpdateCal = false;
            } else if (this.$store.state.workspace.active === 'MyPage') {
              this.$store.dispatch('portal/reloadEvents');
              this.isRequireUpdateCal = false;
            }
          }
        }, 1000 * 60 * 5)
      ,
    };
  },
  mounted() {
    window.App = this;
    this.isDevelopment = process.env.NODE_ENV !== 'production';
    this.ajaxDelay = this.isDevelopment ? 500 : 0;
    this.isLoading = false;
    this.initialized = false;
    this.snackbarTimeout = 3000;
    this.ajaxHistoryMap = {};
    this.routeMap = {};
    this.params = {};
    this.tmpScrollTop = {};
    this.onBack = null;
    this.isEdit = false;
    this.hasClickEvent = false;
    if (this.type === 'mobile') {
      select('body').classed('is-phone', true);
    } else {
      select('body').classed('is-pc', true);
    }
    this.$store.commit('workspace/set', {
      device: this.type,
      isMobile: this.type === 'mobile',
    });
    this.$store.commit('workspace/set', this.workspaceState);
    this.$nextTick(() => {
      // ビュー全体がレンダリングされた後にのみ実行されるコード
      const nav = document.getElementById('nav');
      if (window.SimpleBar && nav) {
        new window.SimpleBar(nav);
      }
      window.addEventListener('load', () => {
        this.$store.commit('workspace/set', { domLoaded: true });
      });
      this.init();
    });

    window.addEventListener('focus', () => {
      aimluck.io.getLocation();
    });
  },
  methods: {
    ...mapActions('calendar', [
      'fetchAllUsers',
    ]),
    init() {
      if (!this.initialized) {
        const afterLazy = (module) => {
          this.lazySetup();

          const { md, LazyContent } = module;
          // iOS13のiPad判定（MobileDetectがiOS13のiPadに対応できたら外す）
          const isIPad = /iPad|Macintosh/i.test(window.navigator.userAgent) && 'ontouchend' in document;
          this.$store.commit('workspace/set', {
            isTablet: md.tablet() !== null || isIPad,
          });
          if (md.tablet() !== null) {
            select('body').classed('is-tablet', true);
          }
          this.$store.commit('workspace/lazyContent', LazyContent);
          let mergeRoutes = module.routes;
          let mergeApps = {};
          mergeApps = [...mergeRoutes['/apps/'], ...module.routesCalendar2['/apps/']];
          mergeRoutes = Object.assign({ '/portal/': mergeRoutes['/portal/'] }, { '/apps/': mergeApps });
          Object.keys(mergeRoutes).forEach((key) => {
            const arrays = mergeRoutes[key];
            arrays.forEach((route) => {
              const path = this.type === 'mobile' && route.mobile && route.mobile.path
                ? route.mobile.path
                : route.path;
              this.routeMap[path] = route;
              if (this.initialRoute !== null && this.initialRoute === path) {
                if (route.type === 'page') {
                  this.$page(`${this.contextPath}/`, (e) => {
                    this.$store.commit('workspace/options', route.options);
                    if (route.pre) {
                      route.pre(this, e);
                    }
                    window.history.pushState(null, '', `${this.contextPath}${this.initialRoute}`);
                    this.showPage(route, e);
                  });
                }
                if (route.type === 'modal') {
                  this.$page(`${this.contextPath}/`, (e) => {
                    this.$store.commit('workspace/options', route.options);
                    if (route.pre) {
                      route.pre(this, e);
                    }
                    this.showModalRoute(route, e);
                  });
                }
                if (route.type === 'custom') {
                  this.$page(`${this.contextPath}/`, (e) => {
                    this.$store.commit('workspace/options', route.options);
                    if (route.pre) {
                      route.pre(this, e);
                    }
                    window.history.pushState(null, '', `${this.contextPath}${this.initialRoute}`);
                    route.action(this, e);
                  });
                }
              }
              if (route.type === 'page') {
                this.$page(this.contextPath + path, (e) => {
                  this.$store.commit('workspace/options', route.options);
                  if (route.pre) {
                    route.pre(this, e);
                  }
                  this.showPage(route, e);
                });
              }
              if (route.type === 'modal') {
                this.$page(this.contextPath + path, (e) => {
                  if (e.init) {
                    this.$store.commit('workspace/options', route.options);
                  }
                  if (route.pre) {
                    route.pre(this, e);
                  }
                  this.showModalRoute(route, e);
                });
              }
              if (route.type === 'custom') {
                this.$page(this.contextPath + path, (e) => {
                  this.$store.commit('workspace/options', route.options);
                  if (route.pre) {
                    route.pre(this, e);
                  }
                  route.action(this, e);
                });
              }
            });
          // }
          });
          this.$page(`${this.contextPath}/portal/*`, (cxt, next) => {
            if (cxt.init) {
              next();
            } else {
              window.location.href = cxt.path;
            }
          });
          this.$page();
          MicroModal.init({ hasAnimation: true, debugMode: false });
          this.initialized = true;
        };
        if (this.type === 'mobile') {
          import(/* webpackChunkName: "mobile-lazy" */ '../mobile/home/modules/lazy.js').then((module) => {
            afterLazy(module);
          });
        } else {
          import(/* webpackChunkName: "lazy" */ './home/modules/lazy.js').then((module) => {
            afterLazy(module);
          });
        }
      }
    },

    lazySetup() {
      this.updateStateFocus();
      this.updateStateBadge();

      window.addEventListener('load', () => {
        this.$store.commit('workspace/set', { domLoaded: true });
      });
      const updateStateFocusFunc = (function Func() {
        window.App.updateStateFocus();
      });
      window.addEventListener('focus', throttle(updateStateFocusFunc, 1000 * 60 * 10));

      if (window.ChannelIO) {
        window.ChannelIO('onBadgeChanged', (count) => {
          if (count) {
            this.setChannelIOUnreadCount(count);
          } else {
            this.setChannelIOUnreadCount(null);
          }
        });
      }
    },

    setUser(user) {
      this.$store.commit('user/set', user);
    },

    setWorkspace(workspace) {
      this.$store.commit('workspace/set', workspace);
    },

    setBadge(badge) {
      this.$store.commit('workspace/set', { badge });
    },

    setChat(chat) {
      this.$store.commit('chat/set', chat);
    },

    setTitle(title) {
      this.$store.commit('workspace/title', title);
      this.$store.commit('workspace/subtitle', '');
    },

    /**
     * スマホ版でチャットのルーム名をページタイトルに表示する
     * @param {*} title
     */
    setSubTitle(title) {
      this.$store.commit('workspace/subtitle', title);
      this.setPinned(false);
    },

    /**
     * ルームが上部に固定化されているか否か
     * @param {*} isPinned
     */
    setPinned(isPinned) {
      this.$store.commit('workspace/isPinned', isPinned);
    },

    setChannelIOUnreadCount(unreadCount) {
      this.$store.commit('workspace/set', { channelIOUnreadCount: unreadCount });
    },

    debug(obj) {
      if (this.isDevelopment) {
        console.log(obj);
      }
    },

    isPhone() {
      return this.device === 'phone';
    },

    setInitialRoute(path) {
      this.initialRoute = path;
    },

    resetTitle() {
      this.title = this.defaultTitle;
    },

    set(nodeId, key, value) {
      this.params[nodeId] = this.params[nodeId] || {};
      this.params[nodeId][key] = value;
    },

    get(nodeId, key) {
      return this.params[nodeId][key];
    },

    navigateTo(path, force = false) {
      if (force) {
        window.location.href = this.contextPath + path;
      } else {
        this.$store.commit('workspace/set', { previousPath: window.location.pathname });
        this.$page(this.contextPath + path);
        window.history.pushState(null, '', window.location.href.split('?')[0]);
      }
    },

    onCloseModal() {
      if (typeof dijit !== 'undefined') {
        const { previousPath } = this.$store.state.workspace;
        const widget = dijit.byId('modalWidget');
        if (widget) {
          widget.destroyRecursive();
        }
        if (previousPath) {
          window.history.pushState(null, '', previousPath);
          this.$store.commit('workspace/set', { previousPath: '' });
        }
        const popup = document.querySelector('.dijitPopup');
        if (popup) {
          popup.parentNode.removeChild(popup);
        }
      }
    },

    showModal(url, portletId, callback) {
      this.$store.commit('workspace/pageProgress', true);
      this.isEdit = false;

      this.closeMenu();
      this.closeDrawer();

      this.isLoading = true;
      this.$api.get(url, {}, { responseType: 'text' }).then((response) => {
        timeout(() => {
          select('#dialog-close-tooltip')
            .attr('data-upgradeed', null)
            .classed('is-active', false);
          select('#dialog-print-tooltip')
            .attr('data-upgraded', null)
            .classed('is-active', false);
          select('#dialog-copy-tooltip')
            .attr('data-upgraded', null)
            .classed('is-active', false);
          this.$store.commit('workspace/pageProgress', false);
          this.isLoading = false;
          const data = this.getData(response);
          toolkit.innerHTML(
            select('#dialog-content').node(),
            data,
            'modalWidget',
            false,
          );
          this.upgradeElement();
          MicroModal.show('modal-dialog', {
            hasAnimation: false,
            onClose() {
              window.App.onCloseModal();
            },
          });
          this.repositionModal();
          try {
            if (callback) {
              callback.call(callback, portletId);
            }
          } catch (e) {
            // ignore
          }
          this.attachChangeEvent();
        }, this.ajaxDelay);
      });
    },

    showModalRoute(route, e) {
      if (this.isLoading) {
        return;
      }
      this.isEdit = false;

      this.closeMenu();
      this.closeDrawer();

      if (this.type === 'desktop') {
        if (route.title) {
          this.$store.commit('workspace/title', route.title);
        }
      }

      let url = this.contextPath
        + (this.type === 'mobile' && route.mobile && route.mobile.endpoint
          ? route.mobile.endpoint
          : route.endpoint);
      if (url) {
        this.$store.commit('workspace/pageProgress', true);
        let portletId = null;
        if (this.$store.state.workspace.portlets[route.portlet]) {
          portletId = this.$store.state.workspace.portlets[route.portlet];
        }
        if (e.querystring) {
          const separator = url.indexOf('?') !== -1 ? '&' : '?';
          url += `${separator}${e.querystring}`;
          window.history.pushState(
            null,
            '',
            window.location.href.split('?')[0],
          );
        }
        Object.keys(e.params).forEach((key) => {
          const value = e.params[key];
          url = toolkit.updateQueryStringParameter(url, key, value);
        });
        if (portletId) {
          url = toolkit.updateQueryStringParameter(url, 'js_peid', portletId);
        }
        let previousPath = '';
        if (route.parent && this.routeMap[route.parent] && this.routeMap[route.parent].type === 'page' && e.init) {
          this.showPage(this.routeMap[route.parent], e);
          previousPath = this.contextPath + route.parent;
        } else if (route.parent && this.routeMap[route.parent] && this.routeMap[route.parent].type === 'custom' && e.init) {
          this.routeMap[route.parent].action(this, e);
          previousPath = this.contextPath + route.parent;
        } else {
          previousPath = window.location.pathname;
        }
        this.$store.commit('workspace/set', { previousPath });
        this.isLoading = true;

        this.$api.get(url, {}, { responseType: 'text' }).then((response) => {
          timeout(() => {
            select('#dialog-close-tooltip')
              .attr('data-upgradeed', null)
              .classed('is-active', false);
            select('#dialog-print-tooltip')
              .attr('data-upgraded', null)
              .classed('is-active', false);
            select('#dialog-copy-tooltip')
              .attr('data-upgraded', null)
              .classed('is-active', false);
            this.$store.commit('workspace/pageProgress', false);
            this.isLoading = false;
            const data = this.getData(response);
            toolkit.innerHTML(
              select('#dialog-content').node(),
              data,
              'modalWidget',
              false,
            );
            this.upgradeElement();
            MicroModal.show('modal-dialog', {
              hasAnimation: false,
              onClose() {
                window.App.onCloseModal();
              },
            });
            this.repositionModal();
            try {
              if (route.onLoad) {
                route.onLoad.call(route.onLoad, portletId);
              }
            } catch (error) {
              // ignore
            }

            if (route.options.hasCopyButton) {
              select('#dialog-copy-button-container').style('display', '');
            } else {
              select('#dialog-copy-button-container').style('display', 'none');
            }

            this.attachChangeEvent();
          }, this.ajaxDelay);
        });
      }
    },

    closeModal(flag) {
      if (typeof flag === 'undefined') {
        this.isEdit = false;
      }
      if (document.querySelector('#modal-dialog.is-open') || document.querySelector('.modal-dialog.is-open')) {
        MicroModal.close('modal-dialog', {
          hasAnimation: true,
        });
      }
    },

    openDrawer() {
      const drawer = select('.drawer.mdl-layout__drawer');
      if (drawer.node() && !drawer.classed('is-visible')) {
        const layout = select('.base-layout').node();
        if (layout) {
          layout.MaterialLayout.toggleDrawer();
        }
      }
    },

    closeDrawer() {
      const drawer = select('.drawer.mdl-layout__drawer');
      if (drawer.node() && drawer.classed('is-visible')) {
        const layout = select('.base-layout').node();
        if (layout) {
          layout.MaterialLayout.toggleDrawer();
        }
      }
    },

    closeMenu() {
      [].forEach.call(document.querySelectorAll('.aui-menu'), (item) => {
        if (item.MaterialMenu) {
          item.MaterialMenu.hide();
        }
      });
    },

    repositionModal() {
      const element = select('#modal-dialog .modal__container').node();

      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      let topValue = scrollTop;
      topValue += (window.innerHeight - element.offsetHeight) / 2;
      element.style.top = `${Math.max(scrollTop, topValue)}px`;
    },

    load(nodeId, url) {
      const node = select(`#${nodeId}`).node();
      if (url && node) {
        this.$api.get(url, {}, { responseType: 'text' }).then((response) => {
          const data = this.getData(response);
          toolkit.innerHTML(node, data);
          this.upgradeElement();
        });
      }
    },

    upgradeElement() {
      toolkit.upgradeElement();

      if (typeof dijit !== 'undefined') {
        const widget = dijit.byId('modalWidget');
        if (!widget) {
          dojo.parser.parse(select('#dialog-content').node());
        }
      }
    },

    upgradeSpinner() {
      forEach(document.querySelectorAll('.aui-spinner:not([data-upgraded])'), (el) => {
        window.componentHandler.upgradeElement(el, 'MaterialSpinner');
      });
    },

    reload(force = false) {
      if (force) {
        window.location.href = window.location.pathname;
      } else {
        this.$page(window.location.pathname);
      }
    },

    showPage(route, e) {
      if (this.isLoading) {
        return;
      }
      this.$store.commit('workspace/headerSubmenu', '');
      this.$store.commit('workspace/fab', '');

      if (this.type === 'desktop') {
        if (route.title) {
          this.$store.commit('workspace/title', route.title);
        }
      }
      this.isLoading = true;
      this.closeMenu();
      this.closeModal();
      this.closeDrawer();

      let url = this.contextPath
        + (this.type === 'mobile' && route.mobile && route.mobile.endpoint
          ? route.mobile.endpoint
          : route.endpoint);
      let portletId = null;
      let resetPortletId = null;
      if (this.$store.state.workspace.portlets[route.portlet]) {
        portletId = this.$store.state.workspace.portlets[route.portlet];
        resetPortletId = portletId;
        this.$store.commit('workspace/active', route.portlet);
      } else if (route.portlet === 'MyPage') {
        this.$store.commit('workspace/active', 'MyPage');
      } else {
        this.$store.commit('workspace/active', '');
      }
      if (route.path === '/portal/admin/') {
        if (this.type === 'mobile') {
          url = toolkit.updateQueryStringParameter(
            url,
            'js_peid',
            this.$store.state.workspace.portlets.SaaSSysInfo,
          );
          resetPortletId = this.$store.state.workspace.portlets.SaaSSysInfo;
        } else {
          url = toolkit.updateQueryStringParameter(
            url,
            'js_pane',
            this.$store.state.workspace.panes.SaaSSysInfo,
          );
          resetPortletId = this.$store.state.workspace.panes.SaaSSysInfo;
        }
      }
      if (route.path === '/portal/settings/') {
        if (this.type === 'mobile') {
          url = toolkit.updateQueryStringParameter(
            url,
            'js_peid',
            this.$store.state.workspace.portlets.AccountPerson,
          );
          resetPortletId = this.$store.state.workspace.portlets.AccountPerson;
        } else {
          url = toolkit.updateQueryStringParameter(
            url,
            'js_pane',
            this.$store.state.workspace.panes.AccountPerson,
          );
          resetPortletId = this.$store.state.workspace.panes.AccountPerson;
        }
      }
      if (typeof dijit !== 'undefined') {
        if (dijit.byId('mbar')) {
          dijit.byId('mbar').destroyRecursive();
        }
      }
      this.$store.commit('workspace/currentTab', null);
      Object.keys(e.params).forEach((key) => {
        const value = e.params[key];
        url = toolkit.updateQueryStringParameter(url, key, value);
        if (key === 'js_peid') {
          resetPortletId = value;
        }
        if (key === 'js_pane') {
          this.$store.commit('workspace/currentTab', value);
          resetPortletId = value;
        }
      });
      if (portletId) {
        url = toolkit.updateQueryStringParameter(url, 'js_peid', portletId);
      }
      if (route.mypage) {
        const mypageId = this.$store.state.workspace.tabs[0]
          ? this.$store.state.workspace.tabs[0].id
          : this.$store.state.workspace.mypageId;
        url = toolkit.updateQueryStringParameter(url, 'js_pane', mypageId);
        this.$store.commit('workspace/currentTab', mypageId);
        resetPortletId = mypageId;
      }
      if (e.querystring) {
        const separator = url.indexOf('?') !== -1 ? '&' : '?';
        url += `${separator}${e.querystring}`;
      }

      this.$store.commit('workspace/component', { component: route.component });

      this.title = route.title;

      this.$store.commit('workspace/pageProgress', true);
      // select('#content').html('');

      if (e.querystring) {
        url += `&${e.querystring}`;
        window.history.pushState(
          null,
          '',
          window.location.href.split('?')[0],
        );
      }
      const activeApp = this.$store.state.workspace.active;
      this.$api.get(url, {}, { responseType: 'text' }).then((response) => {
        timeout(() => {
          if (!e.init) {
            if (aipo.destroyEvent) {
              aipo.destroyEvent();
            }
          }
          if (typeof dijit !== 'undefined') {
            if (resetPortletId) {
              const widget = dijit.byId(`portlet_${resetPortletId}`);
              if (widget) {
                if (widget.destroyRecursive) {
                  widget.destroyRecursive();
                }
              }
            }
          }
          if (window.ptConfig) {
            Object.keys(window.ptConfig).forEach((key) => {
              const widget = dijit.byId(`portlet_${key}`);
              if (widget) {
                if (this.$store.state.workspace.versions.calendar && this.$store.state.workspace.versions.calendar === 2
                  && this.$store.state.workspace.portlets.Schedule && this.$store.state.workspace.portlets.Schedule === key) {
                  // 新カレンダーの検索結果のDOMは削除しない
                } else if (widget.destroyRecursive) {
                  widget.destroyRecursive();
                }
              }
            });
          }
          this.$store.commit('workspace/pageProgress', false);
          this.isLoading = false;
          if (this.sub() && typeof this.sub().destoryPortletInstances !== 'undefined') {
            this.sub().destoryPortletInstances();
          }
          if (this.$store.state.workspace.active === activeApp) {
            const data = this.getData(response);
            toolkit.innerHTML(select('#app-content').node(), data);
          }
          this.upgradeElement();
          if (aipo.onloadSmartPhone) {
            aipo.onloadSmartPhone();
          }
          if (aipo.attachEvent) {
            aipo.attachEvent();
          }

          this.$store.commit('workspace/pageLoaded', true);
        }, this.ajaxDelay);
      });
    },

    showSnackbar(message) {
      const notification = select('.mdl-js-snackbar').node();
      const data = {
        message,
        timeout: this.snackbarTimeout,
      };
      if (notification) {
        notification.MaterialSnackbar.showSnackbar(data);
      }
    },

    showReloadSnackbar() {
      const notification = select('.mdl-js-snackbar').node();
      const data = {
        message: '新しいアップデートがあります。ページを更新してください',
        actionHandler() {
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration().then((registration) => {
              registration.update();
              console.log('serviceWorker update.');
            });
          }
          window.location.reload(true);
        },
        actionText: '今すぐ更新',
        timeout: 3 * 60 * 1000,
      };
      if (notification) {
        notification.MaterialSnackbar.showSnackbar(data);
      }
    },

    getTotalBadge() {
      let count = 0;
      if (this.$store.state.workspace.badge) {
        if (this.$store.state.workspace.badge.activity) {
          count += this.$store.state.workspace.badge.activity;
        }
        if (this.$store.state.workspace.badge.chat) {
          count += this.$store.state.workspace.badge.chat;
        }
        if (this.$store.state.workspace.badge.workflow) {
          count += this.$store.state.workspace.badge.workflow;
        }
        if (this.$store.state.workspace.badge.scheduling) {
          count += this.$store.state.workspace.badge.scheduling;
        }
      }
      return count;
    },

    updateQueryStringParameter(uri, maps) {
      let result = uri;
      Object.keys(maps).forEach((key) => {
        result = toolkit.updateQueryStringParameter(result, key, maps[key]);
      });
      return result;
    },

    resetMobileWidgets() {
      this.$store.commit('workspace/headerSubmenu', '');
      this.$store.commit('workspace/fab', '');

      timeout(() => {
        this.upgradeElement();
      }, 10);
    },
    updateMobileWidgets() {
      const menu = select('#header-submenu');
      if (menu.node() && menu.node().MaterialMenu) {
        menu.node().MaterialMenu.hide();
      }
      let menuHtml = '<ul id="header-submenu" class="aui-menu bottom-right" for="header-submenu-button">';
      const submenu = select('#widgetSubmenu');
      if (submenu.node() && submenu.node().innerHTML) {
        menuHtml += submenu.node().innerHTML;
      }

      const settingsMenus = this.$store.state.workspace.options.settingsMenu;
      const { hasMobileSettingsMenu } = this.$store.state.workspace.options;
      const { activeApps } = this.$store.state.workspace;
      const { trial } = this.$store.state.workspace;
      Object.keys(settingsMenus).forEach((key) => {
        if (hasMobileSettingsMenu) {
          if (settingsMenus[key].label === '表示設定' || (activeApps.includes('AddOnScheduling') && settingsMenus[key].label === '日程調整')) {
            menuHtml += '<li class="hr"><hr></li>';
            menuHtml += '<li class="item" onClick="App.onClickSettingsMobile(';
            menuHtml += key;
            menuHtml += `)">${settingsMenus[key].label}</li>`;
          }
          if (settingsMenus[key].viewKey && trial[settingsMenus[key].viewKey] && trial[settingsMenus[key].viewKey] === 'T') {
            menuHtml += '<li class="item" onClick="App.onClickSettingsMobile(';
            menuHtml += key;
            menuHtml += `)">${settingsMenus[key].label}</li>`;
          }
        }
      });

      menuHtml += '</ul>';

      this.$store.commit('workspace/headerSubmenu', menuHtml);
      this.$store.commit(
        'workspace/fab',
        select('#widgetFab').node().innerHTML,
      );

      timeout(() => {
        this.upgradeElement();
      }, 10);
    },

    updateSearchForm(template) {
      if (template) {
        // const clone = document.importNode(template.content, true);
        // const div = document.createElement('div');
        // div.appendChild(clone);
        this.$store.commit('workspace/searchForm', template.innerHTML);
        template.innerHTML = '';
      } else {
        this.$store.commit('workspace/searchForm', '');
      }
      timeout(() => {
        this.upgradeElement();
      }, 10);
    },
    onClickSettingsMobile(key) {
      const {
        active,
        portlets,
      } = this.$store.state.workspace;
      const { link, type, func } = this.$store.state.workspace.options.settingsMenu[
        key
      ];
      if (type === 'dialog') {
        let portletId = null;
        if (active === 'MyPage') {
          portletId = type.isAdmin
            ? portlets.SaaSSysInfo
            : portlets.AccountPerson;
        } else if (active && portlets) {
          portletId = portlets[active];
        }
        if (portletId) {
          aipo.common.showDialog(toolkit.updateQueryStringParameter(link, 'js_peid', portletId));
        }
      } else if (type === 'page') {
        this.navigateTo(link);
      } else if (type === 'custom') {
        func();
      }
    },

    platform() {
      return window.platform;
    },

    isWindows() {
      return window.platform.os.family.startsWith('Windows');
    },

    isMac() {
      return window.platform.os.family.startsWith('OS X');
    },

    isSafari() {
      return window.platform.name === 'Safari';
    },

    isFirefox() {
      return window.platform.name === 'Firefox';
    },

    isChrome() {
      return window.platform.name === 'Chrome';
    },

    isIE() {
      return window.platform.name === 'IE';
    },

    setBack(callback) {
      this.onBack = callback;
      select('body').classed('is-back', true);
    },

    back() {
      if (this.onBack) {
        this.onBack.call(this.onBack);
        select('body').classed('is-back', false);
      }
    },

    getScrollElement(el) {
      if (window.SimpleBar && el.SimpleBar) {
        return el.SimpleBar.getScrollElement();
      }
      return el;
    },

    copyToClipboard(cUrl) {
      const url = cUrl || window.location.href;

      const func = (e) => {
        e.preventDefault();
        if (window.clipboardData) {
          window.clipboardData.setData('Text', url);
        } else {
          e.clipboardData.setData('text/plain', url);
        }
        this.showSnackbar('URLをクリップボードにコピーしました');
      };
      const dummyText = document.getElementById('dummyText');
      const range = document.createRange();
      const copyTextareaElement = document.createElement('textarea');
      if (this.isSafari()) {
        copyTextareaElement.textContent = url;
        dummyText.appendChild(copyTextareaElement);
        // 選択状態にする
        copyTextareaElement.contentEditable = true;
        copyTextareaElement.readOnly = false;
        copyTextareaElement.setSelectionRange(0, 999999);
        range.selectNode(copyTextareaElement);
      } else {
        dummyText.innerHTML = url;
        range.selectNode(dummyText);
      }
      window.getSelection().addRange(range);
      window.addEventListener('copy', func);
      document.execCommand('copy');
      window.removeEventListener('copy', func);
      if (this.isSafari()) {
        dummyText.removeChild(copyTextareaElement);
      }
    },

    attachChangeEvent() {
      try {
        const dialog = select('#dialog-content');
        dialog.selectAll('input').on('change', () => {
          this.isEdit = true;
        });
        dialog.selectAll('select').on('change', () => {
          this.isEdit = true;
        });
        dialog.selectAll('textarea').on('change', () => {
          this.isEdit = true;
        });
      } catch (ignore) {
        //
      }
    },

    setHasClickEvent(hasEvent) {
      this.hasClickEvent = hasEvent;
    },

    updateStateVersion() {
      const url = '?template=StateAppVersionJSONScreen';
      this.$api.get(url)
        .then((response) => {
          timeout(() => {
            const data = this.getData(response);
            if (data && data.version) {
              const newVersion = data.version;
              const oldVersion = this.$store.state.workspace.version;
              if (oldVersion) {
                if (oldVersion !== newVersion) {
                  this.showReloadSnackbar();
                }
              } else {
                this.$store.commit('workspace/set', data);
              }
            }
          }, this.ajaxDelay);
        });
    },

    updateStatePortlets() {
      this.updateState('?template=StatePortletsJSONScreen', 'workspace');
    },

    updateStateActiveApps() {
      this.updateState(
        '?template=ActiveApplicationJSONScreen',
        'workspace',
        'activeApps',
        'activeApps',
      );
    },

    updateStateTabs() {
      this.updateState('?template=StateTabsJSONScreen', 'workspace', 'tabs');
    },

    updateStateBadge() {
      this.updateState('?template=StateBadgeJSONScreen', 'workspace', 'badge');
    },

    updateStateUser() {
      this.updateState('?template=StateUserJSONScreen', 'user');
    },

    updateStateFocus() {
      this.updateMultiStates('?template=StateFocusJSONScreen');
      this.updateStateGroups();
      if (this.isRequireUpdateCal) {
        this.reloadCalendar();
      }
    },

    updateStateTrialMessage() {
      this.updateState(
        '?template=StateTrialMessageJSONScreen',
        'workspace',
        'productTrialMessage',
      );
    },

    updateStateGroups() {
      this.updateState(
        '?template=StateGroupsJSONScreen',
        'workspace',
        'groups',
      );
    },

    updateStateInformation() {
      this.updateState(
        '?template=StateInformationJSONScreen',
        'workspace',
        'information',
      );
    },

    updateStateCalendar() {
      if (document.hasFocus()) {
        this.reloadCalendar();
      } else {
        this.isRequireUpdateCal = true;
      }
    },

    updateStateCalendarFormVersion(version, params = {}) {
      const showScheduleForm = Object.keys(params).length !== 0;
      if (showScheduleForm) {
        if (!window.confirm('登録画面を切り替えます。編集中の情報は失われますがよろしいですか？')) {
          return;
        }
        this.closeModal();
      }
      const viewChangeOverScheduleForm = () => {
        if (showScheduleForm) {
          this.updateStateUser();
          const { user } = this.$store.state;
          if (version === 2 && params && params.entityId === 'new') {
            // 新登録画面(追加)
            const {
              formStart,
              formEnd,
              allDay,
              isSpan,
            } = params;
            const paramIsSpan = allDay === 'ON' || isSpan ? 'T' : 'F';
            this.navigateTo(`/apps/calendar/events/new/${formStart}/${formEnd}/${user.id}/${paramIsSpan}`);
          } else if (version === 2 && params && params.entityId !== 'new') {
            // 新登録画面(編集)
            const { entityId, isCopy, viewDate } = params;
            let nav = '/apps/calendar/events/';
            nav += isCopy ? `copy/${entityId}/${viewDate}` : `edit/${entityId}/${viewDate}`;
            this.navigateTo(nav);
          } else if (version === 1 && params && !params.entityId) {
            // 旧登録画面(追加)
            const { formStart, formEnd, isSpan } = params;
            const portletId = this.$store.state.workspace.portlets.Schedule;
            const paramIsSpan = isSpan === 'T' ? '&is_span=TRUE' : '';
            const formTo = user.username ? `&member_to=${user.username}` : `&facility_to=${user.id.replace('f', '')}`;
            aipo.common.showDialog(
              `?template=ScheduleFormScreen&entityid=new&mode=form&js_peid=${
                portletId
              }&form_start=${formStart}&form_end=${formEnd}${formTo}${paramIsSpan}`,
              portletId,
              aipo.schedule.onLoadScheduleDialog,
            );
          } else if (version === 1 && params && params.entityId) {
            // 旧登録画面(編集)
            const {
              entityId,
              viewDate,
              isCopyInsert,
            } = params;
            const portletId = this.$store.state.workspace.portlets.Schedule;
            let nav = `?template=ScheduleFormScreen&entityid=${entityId}&mode=form&js_peid=${
              portletId
            }&view_date=${viewDate}`;
            nav += isCopyInsert ? '&is_copy=TRUE' : '';
            aipo.common.showDialog(
              nav,
              portletId,
              aipo.schedule.onLoadScheduleDialog,
            );
          }
        }
      };
      this.updateFormState(
        '?template=CalendarFormVersionFormJSONScreen',
        {
          mode: 'update',
          status: version,
        },
        'user',
        'authority',
        null,
        viewChangeOverScheduleForm,
      );
    },

    updateState(url, module, state = null, loadedKey = null, callback = null) {
      this.$api.get(url)
        .then((response) => {
          timeout(() => {
            const data = this.getData(response);
            if (state) {
              const result = {};
              result[state] = data;
              if (loadedKey) {
                result.isLoaded = {};
                result.isLoaded[loadedKey] = true;
              }
              this.$store.commit(`${module}/set`, result);
            } else {
              this.$store.commit(`${module}/set`, data);
            }

            timeout(() => {
              this.upgradeElement();
              try {
                if (callback) {
                  callback.call(callback);
                }
              } catch (e) {
                // ignore
              }
            }, 1);
          }, this.ajaxDelay);
        });
    },

    updateMultiStates(url) {
      this.$api.get(url)
        .then((response) => {
          timeout(() => {
            const data = this.getData(response);
            let jsondata = {};
            if (data) {
              jsondata = data;
              Object.keys(jsondata).forEach((key) => {
                let module = null;
                let state = null;
                let loadedKey = null;
                let isRequireConfig = false;
                if (key === 'StateUserJSONScreen') {
                  module = 'user';
                } else if (key === 'StateTabsJSONScreen') {
                  module = 'workspace';
                  state = 'tabs';
                } else if (key === 'ActiveApplicationJSONScreen') {
                  module = 'workspace';
                  state = 'activeApps';
                  loadedKey = 'activeApps';
                } else if (key === 'StateBadgeJSONScreen') {
                  module = 'workspace';
                  state = 'badge';
                } else if (key === 'StatePortletsJSONScreen') {
                  module = 'workspace';
                } else if (key === 'StateAppVersionJSONScreen') {
                  isRequireConfig = true;
                } else if (key === 'StateTrialMessageJSONScreen') {
                  module = 'workspace';
                  state = 'productTrialMessage';
                } else if (key === 'InformationJSONScreen') {
                  module = 'workspace';
                  state = 'information';
                }
                if (!isRequireConfig) {
                  if (state) {
                    const result = {};
                    result[state] = jsondata[key];
                    if (loadedKey) {
                      result.isLoaded = {};
                      result.isLoaded[loadedKey] = true;
                    }
                    this.$store.commit(`${module}/set`, result);
                  } else {
                    this.$store.commit(`${module}/set`, jsondata[key]);
                  }
                } else if (jsondata[key].version) {
                  const newVersion = jsondata[key].version;
                  const oldVersion = this.$store.state.workspace.version;
                  if (oldVersion) {
                    if (oldVersion !== newVersion) {
                      this.showReloadSnackbar();
                    }
                  } else {
                    this.$store.commit('workspace/set', jsondata[key]);
                  }
                }
              });
            }
            timeout(() => {
              this.upgradeElement();
            }, 100);
          }, this.ajaxDelay);
        });
    },

    updateFormState(url, data, module, state = null, loadedKey = null, callback = null) {
      data.secid = this.$store.state.user.secid;
      this.$api.post(url, data)
        .then((response) => {
          timeout(() => {
            const responseData = this.getData(response);
            if (state) {
              const result = {};
              result[state] = responseData;
              if (loadedKey) {
                result.isLoaded = {};
                result.isLoaded[loadedKey] = true;
              }
              this.$store.commit(`${module}/set`, result);
            } else {
              this.$store.commit(`${module}/set`, responseData);
            }

            timeout(() => {
              this.upgradeElement();
              try {
                if (callback) {
                  callback.call(callback);
                }
              } catch (e) {
                // ignore
              }
            }, 1);
          }, this.ajaxDelay);
        });
    },
    updateStateActiveUserList(params) {
      const updateActiveUserList = () => {
        const result = {};
        result.activeUserList = params.usersActiveList.map(activeUser => this.$store.state.workspace.usersMap[activeUser.split(':')[1]]).filter(v => v);
        const { isPresentStatusValid } = this.$store.state.user.authority;
        if (isPresentStatusValid) {
          this.$store.commit('workspace/set', result);
          aipo.presence.loadActiveUserList();
        }
      };
      if (typeof params.usersActiveList !== 'undefined') {
        if (this.$store.state.workspace.usersMap === null) {
          this.updateState('?template=StateUsersMapJSONScreen', 'workspace', null, null, updateActiveUserList);
        } else {
          updateActiveUserList();
        }
      }
    },
    async updateStatePresence() {
      // ユーザの在席ステータスを変更した際に、DBからステータスを再読み込みする。
      await this.fetchAllUsers();
    },
    viewPage(url, portletId, params) {
      const els = document.getElementById(`portlet_${portletId}`);
      if (els) {
        window.ptConfig[portletId].reloadUrl = url;
        let hasKeyword = false;
        const data = {};
        if (params) {
          Object.keys(params).forEach((key) => {
            // 検索キーワードがセットされている場合は文字化け回避のためPOSTリクエストにする
            if ((key === 'keyword' || key === 'sword' || key === 'search' || key === 'target_keyword') && params[key] !== '') {
              hasKeyword = true;
              data[key] = params[key];
            } else {
              const value = params[key];
              url = window.toolkit.updateQueryStringParameter(url, key, value);
            }
          });
        }
        if (hasKeyword) {
          this.$api.post(url, data, { responseType: 'text' }).then((response) => {
            timeout(() => {
              const responseData = this.getData(response);
              if (responseData) {
                window.toolkit.downgradeElement(els);
                window.toolkit.innerHTML(
                  els,
                  responseData,
                );
                this.upgradeElement();
              }
            }, this.ajaxDelay);
          });
        } else {
          this.$api.get(url, {}, { responseType: 'text' }).then((response) => {
            timeout(() => {
              const responseData = this.getData(response);
              if (data) {
                window.toolkit.downgradeElement(els);
                window.toolkit.innerHTML(
                  els,
                  responseData,
                );
                this.upgradeElement();
              }
            }, this.ajaxDelay);
          });
        }
      }
    },
    home() {
      // eslint-disable-next-line no-underscore-dangle
      return document.querySelector('#app div').__vue__;
    },
    sub() {
      // eslint-disable-next-line no-underscore-dangle
      return document.querySelector('#app div').__vue__.$refs.sub;
    },
    getData(response) {
      return (typeof response.error !== 'undefined' && response.error !== null) ? response.error.data : response.data;
    },
    /**
     * AIPO_PRODUCT-12132 暫定対応
     * 対象のポートレットが入力中であるかを判定します。
     * @param {*} portletId
     * @returns
     */
    isPortletInputting(portletId) {
      const tlInputTextarea = document.querySelector(`#note_${portletId}`);
      const tlInputAttachmentFile = document.querySelector(`div.tlInputAttachment ul#attachments_${portletId} li`);
      const tlCommentInputTextarea = document.querySelectorAll(`[id^="note_${portletId}_"]`);
      const isCommentInput = Object.values(tlCommentInputTextarea).some(el => el.value && el.value !== '');
      // textareaに文字が入力されている、または添付ファイルが存在する場合は投稿を入力中とする
      return Boolean((tlInputTextarea && tlInputTextarea.value !== '') || tlInputAttachmentFile || isCommentInput);
    },
    /**
     * AIPO_PRODUCT-12132 暫定対応
     * アクティビティからタイムラインポートレットを更新する場合の実行判定 + 更新処理の呼び出し
     * @param {*} group
     */
    temporaryPortletReload(group) {
      const portalConfig = window.ptConfig;
      // AIPO_PRODUCT-12132: (暫定対応)タイムライン画面で入力中の場合はSKIP
      Object
        .keys(portalConfig)
        .filter(portletId => portalConfig[portletId].group === group && !this.isPortletInputting(portletId))
        .forEach(portletId => portalConfig[portletId].reloadFunction.call(portalConfig[portletId].reloadFunction, portletId));
    },
  },
});

export default App;
