/* eslint no-useless-escape: "off" */
/* eslint no-continue: "off" */
import forEach from 'lodash/forEach';

const toolkit = {
  formatDate(year, month, day) {
    if (typeof year !== 'number' || typeof month !== 'number' || typeof day !== 'number') {
      return undefined;
    }
    const m = `0${month}`.slice(-2);
    const d = `0${day}`.slice(-2);
    return `${year}-${m}-${d}`;
  },
  createQueryParameter(data) {
    const queryArray = [];
    Object.keys(data).forEach(key => queryArray.push(`${key}=${encodeURIComponent(data[key])}`));
    return `${queryArray.join('&')}`;
  },
  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
  updateQueryStringParameter(uri, key, value) {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, `$1${key}=${value}$2`);
    }
    return `${uri + separator + key}=${value}`;
  },
  innerHTML(element, value, dijitId, validate = true) {
    this.downgradeElement(element);
    const regexp = /<script>(.|\s)*?\/script>/gi;
    const scripts = value.match(regexp);
    let html = '';
    if (dijitId && dijit) {
      const widget = dijit.byId(dijitId);
      if (widget) {
        widget.destroyRecursive();
      }
      html += `<div dojoType="aimluck.widget.Contentpane" id="${dijitId}" widgetId="${dijitId}">`;
    }
    html += value;
    if (dijitId) {
      html += '</div>';
    }
    element.innerHTML = '';
    if (scripts && validate) {
      // element.innerHTML = html.replace(regexp, '');
      element.insertAdjacentHTML('afterbegin', html.replace(regexp, ''));
      scripts.forEach((script) => {
        const scriptElement = document.createElement('script');
        scriptElement.text = script.replace(/<[\/]*?script>/gi, '');
        element.appendChild(scriptElement);
      });
    } else {
      // element.innerHTML = html;
      element.insertAdjacentHTML('afterbegin', html);
    }
  },

  insertAdjacentHTML(element, value) {
    const regexp = /<script>(.|\s)*?\/script>/gi;
    const scripts = value.match(regexp);
    if (scripts) {
      element.insertAdjacentHTML('beforeend', value);
      element.insertAdjacentHTML('beforeend', value.replace(regexp, ''));
      scripts.forEach((script) => {
        const scriptElement = document.createElement('script');
        scriptElement.text = script.replace(/<[\/]*?script>/gi, '');
        element.appendChild(scriptElement);
      });
    } else {
      element.insertAdjacentHTML('beforeend', value);
    }
  },

  encodeXml(string) {
    const maps = {
      '&': '&amp;',
      '"': '&quot;',
      '<': '&lt;',
      '>': '&gt;',
    };
    return string.replace(/([\&"<>])/g, (str, item) => maps[item]);
  },
  decodeXml(string) {
    const maps = {
      '&amp;': '&',
      '&quot;': '"',
      '&lt;': '<',
      '&gt;': '>',
    };
    return string.replace(
      /(&quot;|&lt;|&gt;|&amp;)/g,
      (str, item) => maps[item],
    );
  },
  serialize(form) {
    if (!form || form.nodeName !== 'FORM') {
      return null;
    }
    let i;
    let j;
    const obj = {};
    for (i = form.elements.length - 1; i >= 0; i -= 1) {
      if (form.elements[i].name === '') {
        continue;
      }
      switch (form.elements[i].nodeName) {
        case 'INPUT':
          switch (form.elements[i].type) {
            case 'text':
            case 'hidden':
            case 'password':
            case 'button':
            case 'reset':
            case 'submit':
              obj[form.elements[i].name] = encodeURIComponent(form.elements[i].value);
              break;
            case 'checkbox':
            case 'radio':
              if (form.elements[i].checked) {
                obj[form.elements[i].name] = encodeURIComponent(form.elements[i].value);
              }
              break;
            case 'file':
              break;
            default:
              break;
          }
          break;
        case 'TEXTAREA':
          obj[form.elements[i].name] = encodeURIComponent(form.elements[i].value);
          break;
        case 'SELECT':
          switch (form.elements[i].type) {
            case 'select-one':
              obj[form.elements[i].name] = encodeURIComponent(form.elements[i].value);
              break;
            case 'select-multiple':
              for (j = form.elements[i].options.length - 1; j >= 0; j -= 1) {
                if (form.elements[i].options[j].selected) {
                  obj[form.elements[i].name] = encodeURIComponent(form.elements[i].options[j].value);
                }
              }
              break;
            default:
              break;
          }
          break;
        case 'BUTTON':
          switch (form.elements[i].type) {
            case 'reset':
            case 'submit':
            case 'button':
              obj[form.elements[i].name] = encodeURIComponent(form.elements[i].value);
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
    return obj;
  },
  upgradeElement(els = null) {
    const d = els || document;
    forEach(d.querySelectorAll('.mdl-js-button:not([data-upgraded])'), (el) => {
      window.componentHandler.upgradeElement(el, 'MaterialButton');
    });
    forEach(d.querySelectorAll('.aui-button:not([data-upgraded])'), (el) => {
      window.componentHandler.upgradeElement(el, 'MaterialButton');
    });
    forEach(d.querySelectorAll('.aui-radio:not([data-upgraded])'), (el) => {
      window.componentHandler.upgradeElement(el, 'MaterialRadio');
    });
    forEach(d.querySelectorAll('.aui-checkbox:not([data-upgraded])'), (el) => {
      window.componentHandler.upgradeElement(el, 'MaterialCheckbox');
    });
    forEach(d.querySelectorAll('.aui-tabs:not([data-upgraded])'), (el) => {
      window.componentHandler.upgradeElement(el, 'MaterialTabs');
    });
    forEach(d.querySelectorAll('.aui-tooltip:not([data-upgraded])'), (el) => {
      window.componentHandler.upgradeElement(el, 'MaterialTooltip');
    });
    forEach(d.querySelectorAll('.aui-menu:not([data-upgraded])'), (el) => {
      window.componentHandler.upgradeElement(el, 'MaterialMenu');
    });
    forEach(d.querySelectorAll('.aui-spinner:not([data-upgraded])'), (el) => {
      window.componentHandler.upgradeElement(el, 'MaterialSpinner');
    });
    forEach(d.querySelectorAll('.aui-switch:not([data-upgraded])'), (el) => {
      window.componentHandler.upgradeElement(el, 'MaterialSwitch');
    });
  },
  downgradeElement(els = null) {
    const d = els || document;
    forEach(d.querySelectorAll('[data-upgraded]'), (el) => {
      window.componentHandler.downgradeElements(el);
    });
  },
  compare(a, b) {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  },
  setViewportProperty(doc) {
    let prevClientHeight;
    const customVar = '--vh';
    function handleResize() {
      const { clientHeight } = doc;
      if (clientHeight === prevClientHeight) return;
      requestAnimationFrame(() => {
        doc.style.setProperty(customVar, `${clientHeight * 0.01}px`);
        prevClientHeight = clientHeight;
      });
    }
    handleResize();
    return handleResize;
  },
};

export default toolkit;
