<template>
  <main>
    <div id="app-content" />
  </main>
</template>

<script>
import SWNotification from '../../sw/modules/notification';

window.SWNotification = new SWNotification();
export default {
  mounted() {
    document.getElementById('app-content').innerHTML = document.getElementById('empty-content').innerHTML;
    document.getElementById('empty-content').innerHTML = '';
    if (aipo.attachEvent) {
      aipo.attachEvent();
    }
  },
};
</script>
