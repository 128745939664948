<template>
  <button
    ref="el"
    class="aui-button"
    type="button"
    tabindex="-1"
  >
    <slot />
  </button>
</template>

<script>
export default {
  mounted() {
    window.componentHandler.upgradeElement(this.$refs.el, 'MaterialButton');
  },
  beforeDestroy() {
    window.componentHandler.downgradeElements(this.$refs.el);
  },
  methods: {},
};
</script>
