<template>
  <popover
    :mouse-event="mouseEvent"
    :outer-rect="outerRect"
    :title="''"
  >
    <div>
      <div
        id="activityListPane"
        class="activity"
      >
        <div
          id="indicator-activityListPane"
          class="spinner-container center"
        >
          <span class="aui-spinner mini is-active" />
        </div>
      </div>
    </div>
  </popover>
</template>

<script>

export default {
  props: {
    mouseEvent: {
      type: Event,
      default: null,
      required: false,
    },
    outerRect: {
      validator: () => true,
      default: null,
      required: false,
    },
  },
  updated() {
    // データが変更後、仮想 DOM が再描画そしてパッチを適用
    this.$nextTick(() => {
    // ビュー全体が再レンダリングされた後にのみ実行されるコード
      aipo.menu.activity.reload();
    });
  },
};
</script>
