export default class SWNotification {
  /**
   * Web Pushの通知先Endpointを取得してindexedDBに保存して画面の表示を更新する
   * @param {*} options
   */
  subscribePushSubscription(options) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if ('pushManager' in registration) {
          registration.pushManager.subscribe(options).then(
            (pushSubscription) => {
              const key = pushSubscription.getKey('p256dh');
              const token = pushSubscription.getKey('auth');
              const params = {
                secid: App.$store.state.user.secid,
                mode: 'insert',
                endpoint: pushSubscription.endpoint,
                userPublicKey: btoa(String.fromCharCode.apply(null, new Uint8Array(key))),
                userAuthToken: btoa(String.fromCharCode.apply(null, new Uint8Array(token))),
              // eslint-disable-next-line no-unused-vars
              }; App.$api.post('?template=SubscribePushSubscriptionEndpointFormJSONScreen', params).then((response) => {
                this.showSuccess();
              }, (error) => {
                this.showError();
                console.warn('serviceWorker error.', error);
              });
            },
          ).catch((error) => {
            this.showError();
            console.warn('serviceWorker register endpoint error.', error);
          });
        } else {
          // pushManagerが無効の場合はインジケータだけを非表示にする
          const indicator = document.getElementById('indicator-dlg-requestDesktopNotification');
          if (indicator) {
            indicator.style.display = 'none';
          }
        }
      }).catch((error) => {
        this.showError();
        console.warn('serviceWorker register endpoint error.', error);
      });
    }
  }


  showSuccess() {
    // アラートを非表示
    const indicator = document.getElementById('indicator-dlg-requestDesktopNotification');
    if (indicator) {
      indicator.style.display = 'none';
    }
    const caution = document.getElementById('caution-requestDesktopNotification');
    if (caution) {
      caution.style.display = 'none';
    }
    // 成功時のメッセージを表示
    const success = document.getElementById('requestDesktopNotificationConfirm');
    if (success) {
      success.style.display = '';
    }
  }

  showError() {
  // アラートを非表示
    const indicator = document.getElementById('indicator-dlg-requestDesktopNotification');
    if (indicator) {
      indicator.style.display = 'none';
    }
    const caution = document.getElementById('caution-requestDesktopNotification');
    if (caution) {
      caution.style.display = '';
    }
  }

  /**
   * ブラウザの通知許可状態
   * @returns
   */
  isGranted() {
    return (window.Notification && window.Notification.permission === 'granted') || (window.webkitNotifications && window.webkitNotifications.checkPermission() === 0);
  }

  /**
   * Web Pushの受信状態をチェック
   * @param {*} portletId
   */
  requestWebPushNotification(portletId) {
    if (this.isGranted()) {
      // ブラウザの通知が有効の場合
      // console.log('requestWebPushNotification start');
      // 現在のオリジンがシステム通知を表示することについて、ユーザーが明示的な許可を与えている。
      // Cookieに保存されている整合性をチェックする
      if (!aipo.getCookie('devicetoken') || !aipo.getCookie('userhash')) {
        // 該当するデータがないメッセージを表示する
        const element = document.getElementById(`webPushNotificationRequestField${portletId}`);
        element.style.display = '';
        const elements = document.getElementsByClassName('notificationSuccessField');
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.display = 'none';
        }
      }
    }
  }
}
