<template>
  <div
    ref="el"
    class="aui-spinner"
    :class="{ 'is-active': isActive }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  mounted() {
    window.componentHandler.upgradeElement(this.$refs.el, 'MaterialSpinner');
  },
  beforeDestroy() {
    window.componentHandler.downgradeElements(this.$refs.el);
  },
  methods: {},
};
</script>
