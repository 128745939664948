<template>
  <div
    ref="el"
    class="page-progress"
  />
</template>

<script>
export default {
  mounted() {
    window.componentHandler.upgradeElement(this.$refs.el, 'MaterialProgress');
  },
  beforeDestroy() {
    window.componentHandler.downgradeElements(this.$refs.el);
  },
};
</script>
