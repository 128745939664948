<template>
  <ul
    ref="el"
    class="aui-menu"
  >
    <slot />
  </ul>
</template>

<script>
export default {
  mounted() {
    window.componentHandler.upgradeElement(this.$refs.el, 'MaterialMenu');
  },
  beforeDestroy() {
    window.componentHandler.downgradeElements(this.$refs.el);
  },
  methods: {},
};
</script>
