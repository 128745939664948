<template>
  <div
    v-show="isShow"
    v-click-outside="onClose"
    :style="getStyle"
    :class="{ 'popover' : !disabled }"
    @click="onInnerClose"
  >
    <div
      v-if="!disabled"
      class="popover__head"
    >
      <div class="popover__title">
        {{ title }}
      </div>
      <aui-button
        class="icon mini"
        @click.native="onClose"
      >
        <i class="fas fa-times" />
      </aui-button>
    </div>
    <slot />
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  directives: {
    ClickOutside,
  },
  components: {
  },
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    mouseEvent: {
      type: Event,
      default: null,
      required: false,
    },
    outerRect: {
      validator: () => true,
      default: null,
      required: false,
    },
    center: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      isShow: false,
      left: 'auto',
      top: 'auto',
      width: 'auto',
      height: 'auto',
    };
  },
  computed: {
    getStyle() {
      const {
        left, right, bottom, top, disabled,
      } = this;
      if (disabled) {
        return {
          height: '100%',
        };
      }
      return {
        visibility: this.mouseEvent ? 'visible' : 'hidden',
        left,
        top,
        right,
        bottom,
      };
    },
  },
  watch: {
    mouseEvent(val) {
      if (val) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
  },
  mounted() {
    if (this.disabled) {
      this.isShow = true;
    }
    this.$nextTick(() => {
    // ビュー全体がレンダリングされた後にのみ実行されるコード
    });
  },
  updated() {
    // データが変更後、仮想 DOM が再描画そしてパッチを適用
    this.$nextTick(() => {
      // ビュー全体が再レンダリングされた後にのみ実行されるコード
      if (this.mouseEvent) {
        const { width, height } = window.getComputedStyle(this.$el);
        const { clientWidth, clientHeight } = document.documentElement;
        let top = 'auto';
        let right = 'auto';
        let left = 'auto';
        let bottom = 'auto';
        if (this.isMobile) {
          // モバイル版は中央揃え
          left = (clientWidth / 2) - (parseInt(width, 10) / 2);
          top = ((clientHeight / 2)) - (parseInt(height, 10) / 2);
        } else {
          const intW = parseInt(width, 10);
          const intH = parseInt(height, 10);
          const {
            clientX, clientY,
          } = this.mouseEvent;
          const offsetX = this.outerRect ? this.outerRect.left : 0;
          const offsetY = this.outerRect ? this.outerRect.top : 0;
          if (this.center) {
            left = clientX - (intW / 2) - offsetX;
            top = clientY - offsetY;
          } else {
            left = clientX + 24 - offsetX;
            top = clientY + 24 - offsetY;
          }
          if (clientWidth - 10 < (this.center ? (clientX + (intW / 2)) : (clientX + intW) + 24)) {
            right = 10;
            left = 'auto';
          }
          if (left < 10) {
            left = 10;
            right = 'auto';
          }
          if (clientHeight - 10 < (this.center ? (clientY + intH) : (clientY + intH) + 24)) {
            // bottom = 10;
            top -= this.center ? intH + 10 : intH + 10;
          }
          if (top < 10) {
            top = 10;
            bottom = 'auto';
          }
        }
        if (left !== 'auto') {
          left += 'px';
        }
        if (right !== 'auto') {
          right += 'px';
        }
        if (top !== 'auto') {
          top += 'px';
        }
        if (bottom !== 'auto') {
          bottom += 'px';
        }
        this.left = left;
        this.top = top;
        this.bottom = bottom;
        this.right = right;
      }
    });
  },
  beforeDestroy() {
    // Vue インスタンスが破棄される直前
  },
  methods: {
    onClose(e) {
      try {
        if (
          !e.target.classList.contains('popover-link')
            && !e.target.parentElement.classList.contains('popover-link') && !this.disabled
        ) {
          this.isShow = false;
          this.$emit('close');
        }
      } catch (ignore) {
        //
      }
    },
    onInnerClose(e) {
      try {
        if (
          (e.target.classList.contains('popover-inner-close-link')
              || e.target.parentElement.classList.contains('popover-inner-close-link')) && !this.disabled
        ) {
          this.isShow = false;
          this.$emit('close');
        }
      } catch (ignore) {
        //
      }
    },
  },
};
</script>

<style lang="scss">
@import 'common/popover';
</style>
