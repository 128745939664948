<template>
  <div
    v-show="isShow"
    :id="key"
    :class="{ normal: !localFullscreen }"
    class="modal-dialog v2"
    aria-hidden="true"
  >
    <div
      tabindex="-1"
      class="modal__overlay"
      data-micromodal-close
    >
      <div
        ref="modal-container"
        :style="style"
        role="dialog"
        class="modal__container"
        aria-modal="true"
      >
        <div
          v-show="pageProgress"
          ref="page-progress"
          class="page-progress"
        />
        <div
          ref="el"
          class="dialog-content"
        >
          <input
            style="display:none"
            type="text"
            tabindex="-1"
            name="modal-dummy"
            autofocus
          >
          <component
            :is="localComponent"
            v-if="localComponent"
            :params="params"
            :page-progress="pageProgress"
            @loading="loading"
            @closeModal="closeModal"
            @changeTop="changeTop"
            @changeWidth="changeWidth"
            @changeFullscreen="changeFullscreen"
            @repositionModal="repositionModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    component: {
      default: null,
      validator: () => true,
    },
    params: {
      type: Object,
      default: () => {},
      required: false,
    },
    width: {
      type: String,
      default: '700px',
    },
    // モバイルの際にフル画面表示にするかどうか
    fullscreen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      key: '',
      isShow: false,
      localWidth: '700px',
      localTop: null,
      localComponent: null,
      localFullscreen: true,
      pageProgress: false,
      isEdit: true,
    };
  },
  computed: {
    style() {
      return this.localTop ? { width: this.localWidth, top: this.localTop } : { width: this.localWidth };
    },
  },
  watch: {
    component(val) {
      if (val) {
        this.isShow = true;
        this.localWidth = this.width;
        this.localComponent = this.component;
        this.localFullscreen = this.fullscreen;
      }
    },
    pageProgress(val) {
      if (val) {
        const els = this.$el.querySelectorAll('button.aui-button');
        Array.prototype.map.call(els, (el) => { el.disabled = true; });
      } else {
        const els = this.$el.querySelectorAll('button.aui-button');
        Array.prototype.map.call(els, (el) => { el.disabled = false; });
      }
    },
  },
  mounted() {
    this.key = `modal-dialog-${Math.floor(Math.random() * 1000000)}`;
    this.localWidth = this.width;
    this.localComponent = this.component;
    window.componentHandler.upgradeElement(this.$refs['page-progress'], 'MaterialProgress');
    this.$nextTick(() => {
    });
  },
  updated() {
    // データが変更後、仮想 DOM が再描画そしてパッチを適用
    this.$nextTick(() => {
      // ビュー全体が再レンダリングされた後にのみ実行されるコード
      if (this.isShow) {
        const self = this;
        MicroModal.show(this.key, {
          hasAnimation: false,
          onClose() {
            self.$emit('close');
            self.isShow = false;
            self.localComponent = null;
            self.localTop = null;
            App.isEdit = false;
          },
        });
        this.needDialog = false;
      }
    });
  },
  beforeDestroy() {
    window.componentHandler.downgradeElements(this.$refs['page-progress']);
  },
  methods: {
    loading(pageProgress) {
      this.pageProgress = pageProgress;
    },
    closeModal() {
      MicroModal.close('modal-dialog', {
        hasAnimation: true,
      });
    },
    changeWidth(newWidth) {
      this.localWidth = newWidth;
    },
    changeTop(newTop) {
      this.localTop = newTop;
    },
    changeFullscreen(isFull) {
      this.localFullscreen = isFull;
    },
    repositionModal() {
      if (!this.localTop) {
        const element = this.$refs['modal-container'];
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        let topValue = scrollTop;
        topValue += (window.innerHeight - element.offsetHeight) / 2;
        element.style.top = `${Math.max(scrollTop - 30, topValue - 30)}px`;
      }
    },
  },
};
</script>
