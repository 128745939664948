import Vue from 'vue';
import Vuex from 'vuex';
import LoadingComponent from './common/components/loading.vue';
import EmptyComponent from './common/containers/empty.vue';

const CalendarComponent = () => ({
  component: import(/* webpackChunkName: "calendar" */ './calendar/containers/calendar.vue'),
  loading: LoadingComponent,
  delay: 0,
});

const ChatComponent = () => ({
  component: import(/* webpackChunkName: "chat" */ './chat/containers/chat.vue'),
  loading: LoadingComponent,
  delay: 0,
});

const SchedulingComponent = () => ({
  component: import(/* webpackChunkName: "scheduling" */ './scheduling/containers/scheduling.vue'),
  loading: LoadingComponent,
  delay: 0,
});

const PortalComponent = () => ({
  component: import(/* webpackChunkName: "portal" */ './portal/containers/portal.vue'),
  loading: LoadingComponent,
  delay: 0,
});

const TimelineComponent = () => ({
  component: import(/* webpackChunkName: "timeline" */ './timeline/containers/timeline.vue'),
  loading: LoadingComponent,
  delay: 0,
});

const ProjectComponent = () => ({
  component: import(/* webpackChunkName: "project" */ './project/containers/project.vue'),
  loading: LoadingComponent,
  delay: 0,
});

const AppsComponent = () => ({
  component: import(/* webpackChunkName: "apps" */ './common/containers/apps.vue'),
  loading: LoadingComponent,
  delay: 0,
});

const WikiComponent = () => ({
  component: import(/* webpackChunkName: "wiki" */ './wiki/containers/wiki.vue'),
  loading: LoadingComponent,
  delay: 0,
});

const CustomizeComponent = () => ({
  component: import(/* webpackChunkName: "customize" */ './customize/containers/customize.vue'),
  loading: LoadingComponent,
  delay: 0,
});

const SettingsComponent = () => ({
  component: import(/* webpackChunkName: "settings" */ './settings/containers/settings.vue'),
  loading: LoadingComponent,
  delay: 0,
});

const AdminComponent = () => ({
  component: import(/* webpackChunkName: "admin" */ './admin/containers/admin.vue'),
  loading: LoadingComponent,
  delay: 0,
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    workspace: {
      strict: process.env.NODE_ENV !== 'production',
      namespaced: true,
      state: {
        domLoaded: false,
        version: null,
        previousPath: '',
        contextPath: '',
        device: 'desktop',
        title: '', // ページタイトル
        subtitle: '',
        isPinned: false,
        prevTitle: '',
        tabs: [], // タブの一覧
        portlets: {},
        panes: {},
        activeApps: [], // ランチャーメニューに表示するアプリの一覧
        trialMessage: '', // トライアルメッセージ
        productTrialMessage: {}, // 製品別トライアルメッセージ
        isTrialEnd: false, // 試用終了したかどうか（有料プランは false）
        mypageId: '',
        settingsPaneId: '', // 個人設定画面のPaneID
        settingsPortletId: '', // 個人設定画面のPortletID
        adminSettingsPaneId: '', // 管理画面のPaneID
        adminSettingsPortletId: '', // 管理画面のPortletID
        active: '', // 現在選択されているアプリ
        pageProgress: false, // 読み込み中
        badge: {
          // バッジ
          activity: null,
          chat: null,
          timeline: null,
          workflow: null,
          report: null,
          note: null,
          scheduling: null,
          msgboard: null,
          wiki: null,
          cabinet: null,
          setup: null,
          setupAccount: null,
          setupNotification: null,
          setupInvite: null,
        },
        currentTab: null, // 現在選択されているタブのID
        currentContent: EmptyComponent, // 現在選択されているComponent
        lazyContent: null,
        currentSubmodule: null,
        fab: '', // FAB
        headerSubmenu: '', // アプリ別のメニュー
        searchForm: '',
        hasSearchForm: false,
        hasSettings: false,
        pageLoaded: false, // 最初のページが読み込まれた
        options: {
          hasSearchForm: false,
          hasMobileSettingsMenu: false,
          settingsMenu: [],
        },
        versions: {
          // アプリケーションのバージョン
          calendar: 1,
        },
        trial: {
          // カレンダーのUI切り替え
          calendar: 'F',
        },
        isLoaded: {},
        topCss: '',
        groups: {
          mygroup: [],
          depertment: [],
          facilitygroup: [],
        },
        headerComponent: null,
        modal: {
          component: null,
          params: {},
          width: '700px',
        },
        isPrintView: false,
        pageParam: {},
        isMobile: false,
        isTablet: false,
        env: {
          isProd: false,
        },
        activityPopup: {
          mouseEvent: null,
          outerRect: null,
        },
        information: [], // Aipoからのお知らせ
        channelIOUnreadCount: null, // チャネルトーク未読件数
        usersMap: null, // login_name -> userId変換用のObject
        activeUserList: [], // アクティブ状態のユーザ一覧
      },
      mutations: {
        set(state, workspace) {
          if (workspace.badge) {
            try {
              const badge = {};
              const onlyIcon = ['report', 'timeline', 'note', 'msgboard', 'wiki', 'cabient', 'setupAccount', 'setupNotification', 'setupInvite'];
              Object.keys(workspace.badge).forEach((key) => {
                try {
                  let obj = workspace.badge[key];
                  if (obj !== null && !onlyIcon.includes(key) && obj > 99) {
                    obj = 99;
                  } else if (
                    obj !== null
                    && onlyIcon.includes(key)
                    && obj > 0
                  ) {
                    obj = ' ';
                  } else if (obj !== null && obj === 0) {
                    obj = null;
                  }
                  badge[key] = obj !== '' ? obj : null;
                } catch (ignore) {
                  // ignore
                }
              });
              workspace = Object.assign(workspace, {
                badge: Object.assign(state.badge, badge),
              });
            } catch (ignore) {
              // ignore
            }
          }
          if (workspace.title) {
            workspace = Object.assign(workspace, { prevTitle: state.title });
          }
          if (typeof workspace.activeUserList !== 'undefined') {
            // activeUserListは常に置換する
            state.activeUserList = workspace.activeUserList;
          }
          state = Object.assign(state, workspace);

          if (aipo.menu) {
            aipo.menu.updateTitle();
          }
        },
        title(state, title) {
          state.prevTitle = state.title;
          state.title = title;
        },
        subtitle(state, subtitle) {
          state.subtitle = subtitle;
        },
        isPinned(state, isPinned) {
          state.isPinned = Boolean(isPinned);
        },
        pageProgress(state, pageProgress) {
          state.pageProgress = pageProgress;
        },
        pageLoaded(state, pageLoaded) {
          state.pageLoaded = pageLoaded;
        },
        active(state, active) {
          state.active = active;
        },
        currentTab(state, currentTab) {
          state.currentTab = currentTab;
        },
        currentContent(state, currentContent) {
          state.currentContent = currentContent;
        },
        lazyContent(state, lazyContent) {
          state.lazyContent = lazyContent;
        },
        component(state, value) {
          const { component } = value;
          state.currentSubmodule = null;
          switch (component) {
            case 'MyPage':
            case 'Portal':
              state.currentContent = PortalComponent;
              state.headerComponent = null;
              break;
            case 'Calendar':
              state.currentContent = CalendarComponent;
              state.currentTab = null;
              state.headerComponent = null;
              break;
            case 'Chat':
              state.currentContent = ChatComponent;
              state.active = 'Message';
              state.headerComponent = null;
              state.currentTab = null;
              break;
            case 'Timeline':
              state.currentContent = TimelineComponent;
              state.currentTab = null;
              state.headerComponent = null;
              break;
            case 'Apps':
              state.currentContent = AppsComponent;
              state.currentTab = null;
              state.headerComponent = null;
              break;
            case 'Project':
              state.currentContent = ProjectComponent;
              state.currentTab = null;
              state.headerComponent = null;
              break;
            case 'Wiki':
              state.currentContent = WikiComponent;
              state.currentTab = null;
              state.headerComponent = null;
              break;
            case 'Settings':
              state.currentContent = SettingsComponent;
              state.currentTab = null;
              state.headerComponent = null;
              break;
            case 'Customize':
              state.currentContent = CustomizeComponent;
              state.currentTab = null;
              state.headerComponent = null;
              break;
            case 'Admin':
              state.currentContent = AdminComponent;
              state.currentTab = null;
              state.headerComponent = null;
              break;
            default:
              state.currentContent = AppsComponent;
              state.currentTab = null;
              state.headerComponent = null;
              break;
          }
        },
        headerComponent(state, value) {
          state.headerComponent = value;
        },
        modal(state, value) {
          state.modal = value;
        },
        apps(state) {
          state.currentContent = AppsComponent;
          state.currentTab = null;
        },
        chat(state) {
          state.currentContent = ChatComponent;
          state.active = 'Message';
          state.currentTab = null;
          state.currentSubmodule = null;
          state.headerComponent = null;
        },
        scheduling(state, pageParam = {}) {
          state.currentContent = SchedulingComponent;
          state.currentSubmodule = 'scheduling';
          state.active = 'Scheduling';
          state.currentTab = null;
          state.pageParam = pageParam;
          state.title = '日程調整';
        },
        schedulingVote(state, pageParam = {}) {
          state.currentContent = SchedulingComponent;
          state.currentSubmodule = 'scheduling';
          state.active = 'SchedulingVote';
          state.currentTab = null;
          state.pageParam = pageParam;
          state.title = '出欠確認';
        },
        calendar2(state) {
          // 新デザイン
          // state.currentContent = Calendar2;
          state.currentContent = CalendarComponent;
          state.currentSubmodule = 'calendar';
          state.active = 'Schedule';
          state.currentTab = null;
          state.fab = '';
        },
        portal(state) {
          state.currentContent = PortalComponent;
        },
        fab(state, fab) {
          state.fab = fab;
        },
        headerSubmenu(state, headerSubmenu) {
          state.headerSubmenu = headerSubmenu;
        },
        searchForm(state, searchForm) {
          state.searchForm = searchForm;
        },
        hasSearchForm(state, hasSearchForm) {
          state.hasSearchForm = hasSearchForm;
        },
        options(state, options) {
          const defaultOptions = {
            hasSearchForm: false,
            settingsMenu: [],
          };
          state.options = Object.assign(defaultOptions, options);
        },
        topCss(state, topCss) {
          state.topCss = topCss;
        },
        resetPageParam(state) {
          state.pageParam = {};
        },
        activityPopup(state, param = {}) {
          state.activityPopup = param;
        },
        information(state) {
          state.information = [];
        },
        channelIOUnreadCount(state, unread) {
          state.channelIOUnreadCount = unread;
        },
      },
    },
    user: {
      strict: process.env.NODE_ENV !== 'production',
      namespaced: true,
      state: {
        id: '',
        name: '',
        username: '',
        company: '',
        profileImageUrl: '',
        isAdmin: false,
        orgId: '',
        secid: null,
        authority: {
          attachmentInsert: false,
          attachmentDelete: false,
          viewUserList: false,
          viewSupport: false,
          Customize: false,
          viewPrint: false,
          isDownloadActive: false,
          isNotificationActive: false,
          calendarFormVersion: '1',
          calendarFormOrgVersion: '1',
          isPresentStatusValid: false,
        },
      },
      mutations: {
        set(state, user) {
          state = Object.assign(state, user);
        },
        secid(state, secid) {
          state.secid = secid;
        },
      },
    },
  },
});
