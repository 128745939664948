/* eslint func-names: ["off", "always"] */
/* eslint no-underscore-dangle: "off" */
/* eslint no-new: "off" */
/* eslint no-use-before-define: "off" */

// MaterialButton

MaterialButton.prototype.init = function () {
  if (this.element_) {
    if (this.element_.classList.contains(this.CssClasses_.RIPPLE_EFFECT)) {
      const rippleContainer = document.createElement('span');
      rippleContainer.classList.add(this.CssClasses_.RIPPLE_CONTAINER);
      this.rippleElement_ = document.createElement('span');
      this.rippleElement_.classList.add(this.CssClasses_.RIPPLE);
      rippleContainer.appendChild(this.rippleElement_);
      this.boundRippleBlurHandler = this.blurHandler_.bind(this);
      this.rippleElement_.addEventListener('mouseup', this.boundRippleBlurHandler);
      this.element_.appendChild(rippleContainer);
    }
    this.boundButtonBlurHandler = this.blurHandler_.bind(this);
    this.element_.addEventListener('mouseup', this.boundButtonBlurHandler);
    this.element_.addEventListener('mouseleave', this.boundButtonBlurHandler);
  }
};

// MaterialCheckbox

MaterialCheckbox.prototype.init = function () {
  if (this.element_) {
    this.inputElement_ = this.element_.querySelector('input[type=checkbox]');

    const boxOutline = document.createElement('span');
    boxOutline.classList.add(this.CssClasses_.BOX_OUTLINE);

    const tickContainer = document.createElement('span');
    tickContainer.classList.add(this.CssClasses_.FOCUS_HELPER);

    const tickOutline = document.createElement('span');
    tickOutline.classList.add(this.CssClasses_.TICK_OUTLINE);

    boxOutline.appendChild(tickOutline);

    this.element_.appendChild(tickContainer);
    this.element_.appendChild(boxOutline);

    if (this.element_.classList.contains(this.CssClasses_.RIPPLE_EFFECT)) {
      this.element_.classList.add(this.CssClasses_.RIPPLE_IGNORE_EVENTS);
      this.rippleContainerElement_ = document.createElement('span');
      this.rippleContainerElement_.classList.add(this.CssClasses_.RIPPLE_CONTAINER);
      this.rippleContainerElement_.classList.add(this.CssClasses_.RIPPLE_EFFECT);
      this.rippleContainerElement_.classList.add(this.CssClasses_.RIPPLE_CENTER);
      this.boundRippleMouseUp = this.onMouseUp_.bind(this);
      this.rippleContainerElement_.addEventListener('mouseup', this.boundRippleMouseUp);

      const ripple = document.createElement('span');
      ripple.classList.add(this.CssClasses_.RIPPLE);

      this.rippleContainerElement_.appendChild(ripple);
      this.element_.appendChild(this.rippleContainerElement_);
    }
    this.boundInputOnChange = this.onChange_.bind(this);
    this.boundInputOnFocus = this.onFocus_.bind(this);
    this.boundInputOnBlur = this.onBlur_.bind(this);
    this.boundElementMouseUp = this.onMouseUp_.bind(this);
    this.inputElement_.addEventListener('change', this.boundInputOnChange);
    this.inputElement_.addEventListener('focus', this.boundInputOnFocus);
    this.inputElement_.addEventListener('blur', this.boundInputOnBlur);
    this.element_.addEventListener('mouseup', this.boundElementMouseUp);

    this.updateClasses_();
    this.element_.classList.add(this.CssClasses_.IS_UPGRADED);
  }
};

// MaterialRadio

MaterialRadio.prototype.onChange_ = function () {
  // Since other radio buttons don't get change events, we need to look for
  // them to update their classes.
  // inputタグにクラス指定をしなくても効くように
  const radios = document.getElementsByClassName('aui-radio');
  for (let i = 0; i < radios.length; i += 1) {
    const button = radios[i].querySelector('input[type=radio]');
    // Different name == different group, so no point updating those.
    if (button.getAttribute('name') === this.btnElement_.getAttribute('name')) {
      if (typeof radios[i].MaterialRadio !== 'undefined') {
        radios[i].MaterialRadio.updateClasses_();
      }
    }
  }
};

MaterialRadio.prototype.init = function () {
  if (this.element_) {
    // inputタグにクラス指定をしなくても効くように
    this.btnElement_ = this.element_.querySelector('input[type=radio]');

    this.boundChangeHandler_ = this.onChange_.bind(this);
    this.boundFocusHandler_ = this.onChange_.bind(this);
    this.boundBlurHandler_ = this.onBlur_.bind(this);
    this.boundMouseUpHandler_ = this.onMouseup_.bind(this);

    const outerCircle = document.createElement('span');
    outerCircle.classList.add(this.CssClasses_.RADIO_OUTER_CIRCLE);

    const innerCircle = document.createElement('span');
    innerCircle.classList.add(this.CssClasses_.RADIO_INNER_CIRCLE);

    this.element_.appendChild(outerCircle);
    this.element_.appendChild(innerCircle);

    let rippleContainer;
    if (this.element_.classList.contains(this.CssClasses_.RIPPLE_EFFECT)) {
      this.element_.classList.add(this.CssClasses_.RIPPLE_IGNORE_EVENTS);
      rippleContainer = document.createElement('span');
      rippleContainer.classList.add(this.CssClasses_.RIPPLE_CONTAINER);
      rippleContainer.classList.add(this.CssClasses_.RIPPLE_EFFECT);
      rippleContainer.classList.add(this.CssClasses_.RIPPLE_CENTER);
      rippleContainer.addEventListener('mouseup', this.boundMouseUpHandler_);

      const ripple = document.createElement('span');
      ripple.classList.add(this.CssClasses_.RIPPLE);

      rippleContainer.appendChild(ripple);
      this.element_.appendChild(rippleContainer);
    }

    this.btnElement_.addEventListener('change', this.boundChangeHandler_);
    this.btnElement_.addEventListener('focus', this.boundFocusHandler_);
    this.btnElement_.addEventListener('blur', this.boundBlurHandler_);
    this.element_.addEventListener('mouseup', this.boundMouseUpHandler_);

    this.updateClasses_();
    this.element_.classList.add(this.CssClasses_.IS_UPGRADED);
  }
};

MaterialSwitch.prototype.init = function () {
  if (this.element_) {
    // inputタグにクラス指定をしなくても効くように
    this.inputElement_ = this.element_.querySelector('input[type=checkbox]');

    const track = document.createElement('div');
    track.classList.add(this.CssClasses_.TRACK);

    const thumb = document.createElement('div');
    thumb.classList.add(this.CssClasses_.THUMB);

    const focusHelper = document.createElement('span');
    focusHelper.classList.add(this.CssClasses_.FOCUS_HELPER);

    thumb.appendChild(focusHelper);

    this.element_.appendChild(track);
    this.element_.appendChild(thumb);

    this.boundMouseUpHandler = this.onMouseUp_.bind(this);

    if (this.element_.classList.contains(this.CssClasses_.RIPPLE_EFFECT)) {
      this.element_.classList.add(this.CssClasses_.RIPPLE_IGNORE_EVENTS);
      this.rippleContainerElement_ = document.createElement('span');
      this.rippleContainerElement_.classList.add(this.CssClasses_.RIPPLE_CONTAINER);
      this.rippleContainerElement_.classList.add(this.CssClasses_.RIPPLE_EFFECT);
      this.rippleContainerElement_.classList.add(this.CssClasses_.RIPPLE_CENTER);
      this.rippleContainerElement_.addEventListener('mouseup', this.boundMouseUpHandler);

      const ripple = document.createElement('span');
      ripple.classList.add(this.CssClasses_.RIPPLE);

      this.rippleContainerElement_.appendChild(ripple);
      this.element_.appendChild(this.rippleContainerElement_);
    }

    this.boundChangeHandler = this.onChange_.bind(this);
    this.boundFocusHandler = this.onFocus_.bind(this);
    this.boundBlurHandler = this.onBlur_.bind(this);

    this.inputElement_.addEventListener('change', this.boundChangeHandler);
    this.inputElement_.addEventListener('focus', this.boundFocusHandler);
    this.inputElement_.addEventListener('blur', this.boundBlurHandler);
    this.element_.addEventListener('mouseup', this.boundMouseUpHandler);

    this.updateClasses_();
    this.element_.classList.add('is-upgraded');
  }
};

// MaterialTooltip
MaterialTooltip.prototype.CssClasses_ = {
  IS_ACTIVE: 'is-active',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
};

MaterialTooltip.prototype.handleMouseEnter_ = function (event) {
  const props = event.target.getBoundingClientRect();
  let left = props.left + (props.width / 2);
  const top = props.top + (props.height / 2);

  // const modal = document.querySelector('.is-open .modal__container');
  const adjustTop = 0;
  const adjustLeft = 0;
  // if (modal) {
  //   //  if (window.platform && (window.platform.name.toLowerCase() === 'chrome' || window.platform.name.toLowerCase() === 'firefox')) {
  //   //  const pos = modal.getBoundingClientRect();
  //   //  adjustTop = pos.y;
  //   //  adjustLeft = pos.x;
  //   //  }
  //   // 20240202 Safariでの位置調整が不要になったためコメントアウト
  //   if (window.platform && (window.platform.name.toLowerCase() === 'safari')) {
  //     const pos = modal.getBoundingClientRect();
  //     adjustTop = pos.top;
  //     adjustLeft = pos.left;
  //   }
  // }

  const marginLeft = -1 * (this.element_.offsetWidth / 2);
  const marginTop = -1 * (this.element_.offsetHeight / 2);

  if (this.element_.classList.contains(this.CssClasses_.LEFT) || this.element_.classList.contains(this.CssClasses_.RIGHT)) {
    left = (props.width / 2);
    if ((top + marginTop) - adjustTop < 0) {
      this.element_.style.top = '0';
      this.element_.style.marginTop = '0';
    } else {
      this.element_.style.top = `${top - adjustTop}px`;
      this.element_.style.marginTop = `${marginTop}px`;
    }
  } else if ((left + marginLeft) - adjustLeft < 0) {
    this.element_.style.left = '0';
    this.element_.style.marginLeft = '0';
  } else {
    this.element_.style.left = `${left - adjustLeft}px`;
    this.element_.style.marginLeft = `${marginLeft}px`;
  }

  if (this.element_.classList.contains(this.CssClasses_.TOP)) {
    this.element_.style.top = `${props.top - this.element_.offsetHeight - 10 - adjustTop}px`;
  } else if (this.element_.classList.contains(this.CssClasses_.RIGHT)) {
    this.element_.style.left = `${(props.left + props.width + 10) - adjustLeft}px`;
  } else if (this.element_.classList.contains(this.CssClasses_.LEFT)) {
    this.element_.style.left = `${props.left - this.element_.offsetWidth - 10 - adjustLeft}px`;
  } else {
    this.element_.style.top = `${(props.top + props.height + 10) - adjustTop}px`;
  }

  this.element_.classList.add(this.CssClasses_.IS_ACTIVE);
};

// MaterialMenu

MaterialMenu.prototype.CssClasses_ = {
  CONTAINER: 'mdl-menu__container',
  OUTLINE: 'mdl-menu__outline',
  ITEM: 'item',
  ITEM_RIPPLE_CONTAINER: 'mdl-menu__item-ripple-container',
  RIPPLE_EFFECT: 'mdl-js-ripple-effect',
  RIPPLE_IGNORE_EVENTS: 'mdl-js-ripple-effect--ignore-events',
  RIPPLE: 'mdl-ripple',
  // Statuses
  IS_UPGRADED: 'is-upgraded',
  IS_VISIBLE: 'is-visible',
  IS_ANIMATING: 'is-animating',
  // Alignment options
  BOTTOM_LEFT: 'bottom-left', // This is the default.
  BOTTOM_RIGHT: 'bottom-right',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  UNALIGNED: 'unaligned',
};

MaterialMenu.prototype.init = function () {
  if (this.element_) {
    // Create container for the menu.
    const container = document.createElement('div');
    container.classList.add(this.CssClasses_.CONTAINER);
    this.element_.parentElement.insertBefore(container, this.element_);
    this.element_.parentElement.removeChild(this.element_);
    container.appendChild(this.element_);
    this.container_ = container;

    // Create outline for the menu (shadow and background).
    const outline = document.createElement('div');
    outline.classList.add(this.CssClasses_.OUTLINE);
    this.outline_ = outline;
    container.insertBefore(outline, this.element_);

    // Find the "for" element and bind events to it.
    const forElId = this.element_.getAttribute('for')
                     || this.element_.getAttribute('data-mdl-for');
    let forEl = null;
    if (forElId) {
      forEl = document.getElementById(forElId);
      if (forEl) {
        this.forElement_ = forEl;
        forEl.addEventListener('click', this.handleForClick_.bind(this));
        forEl.addEventListener(
          'keydown',
          this.handleForKeyboardEvent_.bind(this),
        );
      }
    }

    const items = this.element_.querySelectorAll(`.${this.CssClasses_.ITEM}`);
    this.boundItemKeydown_ = this.handleItemKeyboardEvent_.bind(this);
    this.boundItemClick_ = this.handleItemClick_.bind(this);
    for (let i = 0; i < items.length; i += 1) {
      // Add a listener to each menu item.
      items[i].addEventListener('click', this.boundItemClick_);
      // Add a tab index to each menu item.
      items[i].tabIndex = '-1';
      // Add a keyboard listener to each menu item.
      items[i].addEventListener('keydown', this.boundItemKeydown_);
    }

    // Add ripple classes to each item, if the user has enabled ripples.
    if (this.element_.classList.contains(this.CssClasses_.RIPPLE_EFFECT)) {
      this.element_.classList.add(this.CssClasses_.RIPPLE_IGNORE_EVENTS);

      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];

        const rippleContainer = document.createElement('span');
        rippleContainer.classList.add(this.CssClasses_.ITEM_RIPPLE_CONTAINER);

        const ripple = document.createElement('span');
        ripple.classList.add(this.CssClasses_.RIPPLE);
        rippleContainer.appendChild(ripple);

        item.appendChild(rippleContainer);
        item.classList.add(this.CssClasses_.RIPPLE_EFFECT);
      }
    }

    // Copy alignment classes to the container, so the outline can use them.
    if (this.element_.classList.contains(this.CssClasses_.BOTTOM_LEFT)) {
      this.outline_.classList.add(this.CssClasses_.BOTTOM_LEFT);
    }
    if (this.element_.classList.contains(this.CssClasses_.BOTTOM_RIGHT)) {
      this.outline_.classList.add(this.CssClasses_.BOTTOM_RIGHT);
    }
    if (this.element_.classList.contains(this.CssClasses_.TOP_LEFT)) {
      this.outline_.classList.add(this.CssClasses_.TOP_LEFT);
    }
    if (this.element_.classList.contains(this.CssClasses_.TOP_RIGHT)) {
      this.outline_.classList.add(this.CssClasses_.TOP_RIGHT);
    }
    if (this.element_.classList.contains(this.CssClasses_.UNALIGNED)) {
      this.outline_.classList.add(this.CssClasses_.UNALIGNED);
    }

    container.classList.add(this.CssClasses_.IS_UPGRADED);

    // widthの最大値を指定するhidden要素があれば設定する
    if (this.element_) {
      for (let i = 0; i < this.element_.childElementCount; i++) {
        const child = this.element_.children[i];
        if (child != null && child.name === 'max-width') {
          container.style.maxWidth = child.value;
          break;
        }
      }
    }
  }
};

// MaterialTabs

MaterialTabs.prototype.setTab = function (tab) {
  tab = (typeof tab === 'number') ? this.tabs_[tab] : tab;
  if (tab && tab.getAttribute('href') && tab.getAttribute('href').charAt(0) === '#') {
    const href = tab.href.split('#')[1];
    const panel = this.element_.querySelector(`#${href}`);
    this.resetTabState_();
    this.resetPanelState_();
    tab.classList.add(this.CssClasses_.ACTIVE_CLASS);
    panel.classList.add(this.CssClasses_.ACTIVE_CLASS);
  } else {
    // href に DOM の ID を指定しなかった場合はタブの状態のみ更新
    this.resetTabState_();
    this.resetPanelState_();
    tab.classList.add(this.CssClasses_.ACTIVE_CLASS);
  }
};

MaterialTabs.prototype.initTabs_ = function () {
  if (this.element_.classList.contains(this.CssClasses_.MDL_JS_RIPPLE_EFFECT)) {
    this.element_.classList.add(this.CssClasses_.MDL_JS_RIPPLE_EFFECT_IGNORE_EVENTS);
  }

  // Select element tabs, document panels
  this.tabs_ = this.element_.getElementsByClassName('tab');
  this.panels_ = this.element_.querySelectorAll(`.${this.CssClasses_.PANEL_CLASS}`);

  // Create new tabs for each tab element
  for (let i = 0; i < this.tabs_.length; i += 1) {
    // href に DOM の ID を指定しなかった場合はタブの状態のみ更新
    new CustomMaterialTab(this.tabs_[i], this);
  }

  this.element_.classList.add(this.CssClasses_.UPGRADED_CLASS);
};

function CustomMaterialTab(tab, ctx) {
  if (tab) {
    // mdl-js-ripple-effect を指定しなくても効くように
    if (ctx.element_.classList.contains(ctx.CssClasses_.MDL_JS_RIPPLE_EFFECT)) {
      const rippleContainer = document.createElement('span');
      rippleContainer.classList.add(ctx.CssClasses_.MDL_RIPPLE_CONTAINER);
      rippleContainer.classList.add(ctx.CssClasses_.MDL_JS_RIPPLE_EFFECT);
      const ripple = document.createElement('span');
      ripple.classList.add(ctx.CssClasses_.MDL_RIPPLE);
      rippleContainer.appendChild(ripple);
      tab.appendChild(rippleContainer);
    }

    tab.addEventListener('click', (e) => {
      if (tab.getAttribute('href') && tab.getAttribute('href').charAt(0) === '#') {
        e.preventDefault();
        ctx.setTab(tab);
      } else {
        // href に DOM の ID を指定しなかった場合はタブの状態のみ更新
        ctx.setTab(tab);
      }
    });
  }
}

// MaterialTooltip

MaterialTooltip.prototype.init = function () {
  if (this.element_) {
    const forElId = this.element_.getAttribute('for')
      || this.element_.getAttribute('data-mdl-for');

    if (forElId) {
      this.forElement_ = document.getElementById(forElId);
    } else {
      // data-mdl-for が指定されていない場合は、ひとつ前の要素を選択するように
      this.forElement_ = this.element_.previousElementSibling;
    }

    if (this.forElement_) {
      // It's left here because it prevents accidental text selection on Android
      if (!this.forElement_.hasAttribute('tabindex')) {
        this.forElement_.setAttribute('tabindex', '0');
      }

      this.boundMouseEnterHandler = this.handleMouseEnter_.bind(this);
      this.boundMouseLeaveAndScrollHandler = this.hideTooltip_.bind(this);
      this.forElement_.addEventListener(
        'mouseenter',
        this.boundMouseEnterHandler,
        false,
      );
      this.forElement_.addEventListener(
        'touchend',
        this.boundMouseEnterHandler,
        false,
      );
      this.forElement_.addEventListener(
        'mouseleave',
        this.boundMouseLeaveAndScrollHandler,
        false,
      );
      // Ajaxページ読み込みの度にイベントが追加されるため除去
      /**
      window.addEventListener(
        'scroll',
        this.boundMouseLeaveAndScrollHandler,
        true,
      );
      window.addEventListener(
        'touchstart',
        this.boundMouseLeaveAndScrollHandler,
      );
      */
    }
  }
};
